<template>
  <multiselect
    as="multiselect"
    class="multiselect-multiple"
    v-model="selected"
    :placeholder="placeholder"
    :show-labels="false"
    :options="options"
    :label="label"
    :track-by="trackBy"
    multiple
    :close-on-select="false"
    :class="[invalidClass]"
  >
    <template #tag>
      <span></span>
    </template>
  </multiselect>

</template>

<script>
import { http } from '@/utils'
import { useIsInvalid } from '@chameleon/chameleon-vue'

export default {
  mixins: [useIsInvalid],
  async created () {
    const locationsResponse = await http.get('/lists/locations')
    this.options = locationsResponse.data.data
  },

  props: {
    value: {
      type: Array
    },
    abbreviation: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      options: [],
      label: 'name',
      trackBy: 'id',
      placeholder: 'Add Locations',
    }
  },

  computed: {

    selected: {
      get () {
        const ids = this.value.map(({ id }) => id)
        return this.options.filter(({ id }) => {
          return ids.includes(id)
        })
      },

      set (values) {
        this.$emit('input', values)
      }
    }
  }
}

</script>

<style>
  .list-data {
    margin-bottom: 0;
  }
</style>
