<template>
  <li>
    <div class="row gx-2">
      <div class="col-lg-3 col-xxl-2">
        <p class="text-muted">{{ revision.revised_at | date({ format: 'DD/MM/YYYY [at] HH:mm:ss' }) }}</p>
      </div>
      <div class="col-9 col-lg-7 col-xxl-8">
        <p v-if="revision.causer === null">System</p>
        <p v-else>{{ revision.causer.name }} ({{ revision.causer.email }})</p>
      </div>
      <div class="col-3 col-lg-2 col-xxl-2 text-end">
        <span class="badge badge-primary">
          {{ event }}
        </span>
      </div>
    </div>

    <span v-if="revision.subject === 'course_accreditation_body' && revision.additional.accreditation_body_id" class="revision-additional">
      Accreditation Body: {{ revision.additional.accreditation_body_id }}
    </span>

    <div class="row gx-2" v-for="({ before, after }, key) in revision.changes" :key="key">
      <div class="col-lg-3 col-xxl-2">
        <strong>{{ key }}</strong>
      </div>
      <div class="col-lg-9 col-xxl-10">
        <div class="row flex-grow-1 gx-2">
          <div class="col">{{ before }}</div>
          <div class="col-1 text-center">
            <i class="far fa-arrow-right"></i>
          </div>
          <div class="col">{{ after }}</div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    revision: Object
  },

  computed: {
    event () {
      switch (this.revision.event) {
        case 'deleted':
          return 'Deleted'
        case 'updated':
          return 'Updated'
        case 'created':
          return 'Created'
      }

      return null
    }
  }
}
</script>
