<template>
  <article class="card card-course card-course-horizontal">
    <router-link :to="{ name: 'courses.show', params: { courseId: course.id } }" class="featured-container">
      <img class="featured" :src="featuredImageUrl" width="1280" height="720" />
      <span class="featured-topic" v-if="course.featured_topic_name">
        {{ course.featured_topic_name }}
      </span>
    </router-link>

    <status-label v-bind="{ status: course.status }"/>

    <slot name="like-btn" />

    <span
      class="previous-marker"
      title="Past Event"
      v-c-tooltip
      v-if="course.previous"
    >
      <i class="far fa-history"/>
    </span>

    <router-link :to="{ name: 'courses.show', params: { courseId: course.id } }" class="card-body">
      <h2>{{ course.name }}</h2>

      <p class="text-muted">{{ course.provider_name }}</p>

      <learner-quality-score :course="course"></learner-quality-score>

      <ul class="tag-list">
        <course-tag :course="course"/>
      </ul>

      <p>{{ course.short_description }}</p>
    </router-link>
  </article>
</template>

<script>
import { placeholders } from '@/utils/placeholders'
import StatusLabel from '../StatusLabel.vue'
import CourseTag from '../CourseTag.vue'
import LearnerQualityScore from '../LearnerQualityScore.vue'

export default {
  components: {
    StatusLabel,
    CourseTag,
    LearnerQualityScore
  },

  props: {
    course: Object
  },

  computed: {
    featuredImageUrl () {
      return this.course.featured_image_url ?? placeholders.large
    }
  },
}
</script>
