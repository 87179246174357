<template>
  <div class="mb-3">
    <p class="form-label">Topics</p>

    <div class="card">
      <ul
        class="list-data list-data-course-topics no-links no-hover has-button"
      >
        <template v-for="topic in topicsGrouped">
          <li :key="`topic-${topic.id}`">
            <div class="row">
              <div
                class="col flex-column align-items-start justify-content-center"
              >
                <span>{{ topic.name }}</span>

                <validation-provider
                  slim
                  :vid="topic.idKey"
                  v-slot="{ failed, errors }"
                >
                  <span
                    v-if="failed"
                    class="invalid-feedback d-block fw-normal"
                    >{{ errors[0] }}</span
                  >
                </validation-provider>
                <validation-provider
                  slim
                  :vid="topic.featuredKey"
                  v-slot="{ failed, errors }"
                >
                  <span
                    v-if="failed"
                    class="invalid-feedback d-block fw-normal"
                    >{{ errors[0] }}</span
                  >
                </validation-provider>
              </div>
              <div class="col-auto">
                <div class="form-check form-check-star me-2">
                  <input
                    :id="`topic-check-${topic.id}`"
                    class="form-check-input"
                    type="radio"
                    :checked="topic.featured"
                    @click="updateFeaturedTopic(topic)"
                  />
                  <label :for="`topic-check-${topic.id}`"></label>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-sm"
              @click="removeTopic(topic)"
            >
              <i class="far fa-trash-alt"></i>
            </button>
          </li>
          <li
            class="sub"
            v-for="child in topic.children"
            :key="`topic-${child.id}`"
          >
            <div class="row">
              <div
                class="
                  col
                  d-flex
                  flex-column
                  align-items-start
                  justify-content-center
                "
              >
                {{ child.name }}

                <validation-provider
                  slim
                  :vid="child.idKey"
                  v-slot="{ failed, errors }"
                >
                  <span
                    v-if="failed"
                    class="invalid-feedback d-block fw-normal"
                    >{{ errors[0] }}</span
                  >
                </validation-provider>
                <validation-provider
                  slim
                  :vid="child.featuredKey"
                  v-slot="{ failed, errors }"
                >
                  <span
                    v-if="failed"
                    class="invalid-feedback d-block fw-normal"
                    >{{ errors[0] }}</span
                  >
                </validation-provider>
              </div>
              <div class="col-auto">
                <div class="form-check form-check-star me-2">
                  <input
                    :id="`topic-check-${child.id}`"
                    class="form-check-input"
                    type="radio"
                    :checked="child.featured"
                    @click="updateFeaturedTopic(child)"
                  />
                  <label :for="`topic-check-${child.id}`"></label>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-sm"
              @click="removeTopic(child)"
            >
              <i class="far fa-trash-alt"></i>
            </button>
          </li>
        </template>
      </ul>
      <validation-provider
        tag="div"
        name="topics"
        rules="required"
        class="card-body pt-0 p-2 d-flex align-items-center"
        v-slot="{ failed }"
      >
        <input
          type="hidden"
          :value="topicsSelected.join(',')"
          :class="{ 'is-invalid': failed }"
        />
        <c-help-block class="w-auto" />
        <button
          type="button"
          class="ms-auto btn btn-sm btn-primary"
          v-c-modal:topics
          :disabled="!professionIds.length"
        >
          <i class="far fa-plus"></i> Topic
        </button>
      </validation-provider>
    </div>

    <select-topics-drawer v-model="topicsSelected" ref="topics" :professions="professionIds" />
  </div>
</template>

<script>
import { useTopicsSelected } from '../topics/use-topics-selected'
import { get, sync } from 'vuex-pathify'
import SelectTopicsDrawer from '@/components/topics/SelectTopicsDrawer.vue'

export default {
  mixins: [useTopicsSelected({ flattenedTopicsKey: 'topics', resourceTopicsKey: 'courseTopics' })],
  components: {
    SelectTopicsDrawer
  },

  props: {
    professionIds: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    topics: get('lists/topics/flattened'),
    courseTopics: sync('courses/course@topics'),

    topicsGrouped () {
      const validationKeys = (topic) => {
        const index = this.courseTopics.findIndex(({ id }) => id === topic.id)

        return {
          idKey: `topics.${index}.id`,
          featuredKey: `topics.${index}.featured`,
        }
      }

      return this.courseTopics.filter(topic => topic.parent_id === null)
        .map((topic) => ({
          ...topic,
          ...validationKeys(topic),
          children: this.courseTopics.filter((child) => child.parent_id === topic.id)
            .map((child) => ({
              ...child,
              ...validationKeys(child)
            }))
        }))
    }
  },

  methods: {
    removeTopic (topic) {
      const topics = [...this.courseTopics]

      // Get any children topics for the topic to be removed and remove them from the
      // array of selected topics first.
      const children = topics.filter(child => child.parent_id === topic.id)

      for (const child of children) {
        topics.splice(topics.findIndex(({ id }) => id === child.id), 1)
      }

      // Remove the selected topic.
      topics.splice(topics.findIndex(({ id }) => id === topic.id), 1)

      // Force featured topic by making the first topic in the group featured.
      if (topics.length > 0 && topics.find(({ featured }) => featured === true) === undefined) {
        const featured = this.topicsGrouped[0]

        topics.splice(topics.findIndex(({ id }) => id === featured.id), 1, { ...featured, featured: true })
      }

      this.courseTopics = topics
    },

    updateFeaturedTopic (topic) {
      const topics = [...this.courseTopics]
      const newFeaturedIndex = topics.findIndex(({ id }) => id === topic.id)
      const existingFeaturedIndex = topics.findIndex(({ featured }) => featured === true)

      // If existing featured index is the same as the new one user has clicked the same topic, we do nothing.
      if (existingFeaturedIndex === newFeaturedIndex) {
        return
      }

      // Remove the featured flag from the existing featured topic.
      if (existingFeaturedIndex !== -1) {
        topics.splice(existingFeaturedIndex, 1, { ...topics[existingFeaturedIndex], featured: false })
      }

      topics.splice(newFeaturedIndex, 1, { ...topic, featured: true })

      this.courseTopics = topics
    }
  }
}
</script>
