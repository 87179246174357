<template>
  <input type="text" class="form-control" placeholder="Address" :value="address"/>
</template>

<script>
import { maps } from '@/utils/google-maps'

export default {
  props: {
    map: Object,
    value: Object,
    defaultAddress: String,
    countries: {
      required: false,
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => ['geometry', 'formatted_address', 'address_components']
    },
    zoomTo: {
      type: Number,
      default: 15
    }
  },

  data () {
    return {
      autocomplete: null,
      marker: null
    }
  },

  computed: {
    address () {
      if (this.value && this.value.formatted_address) {
        return this.value.formatted_address
      }

      return this.defaultAddress
    }
  },

  async mounted () {
    if (!maps.done) {
      await maps.load()
    }

    this.autocomplete = new window.google.maps.places.Autocomplete(this.$el, {
      fields: this.fields,
      componentRestrictions: {
        country: this.countries
      }
    })

    this.autocomplete.addListener('place_changed', this.placeChanged)
  },

  methods: {
    placeChanged () {
      const place = this.autocomplete.getPlace()

      if (!place.geometry || !place.address_components) {
        return
      }

      if (this.map) {
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport)
        } else {
          this.map.setCenter(place.geometry.location)
          this.map.setZoom(this.zoomTo)
        }
      }

      const components = place.address_components.reduce((components, component) => {
        for (const type of component.types) {
          components.long[type] = component.long_name
          components.short[type] = component.short_name
        }

        return components
      }, { long: {}, short: {} })

      this.$emit('input', {
        place,
        components,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        formattedAddress: place.formatted_address
      })
    }
  }
}
</script>
