<template>
  <fragment>
    <div class="card" v-if="questions.length">
      <div class="card-body">
        <h3 class="mb-3">Learner Quality Score</h3>
        <p class="text-muted fs-6 mb-3">Provider Average</p>
        <div class="col-12 col-xxl-8">
            <div class="score-n-responses">
              <div class="col-12 col-xs-6 col-lg-3">
                  <div class="d-flex align-items-center">
                    <p class="text-muted mb-0 fs-6 align-middle me-4"><i class="far fa-clipboard-check fa-fw me-1"></i>
                      {{ course ? learnerQualityScore : providerAverage }}
                    </p>
                    <p class="text-muted mb-0 fs-6 align-middle"><i class="far fa-user-check fa-fw me-1"></i>
                        {{ course ? courseParticipantsCount : providerParticipantsCount }}
                    </p>
                  </div>
              </div>
            </div>
        </div>
        <hr>
        <template v-for="question in questions">
            <div class="row align-items-center p-3" :key="`question-${question.category}`">
              <div class="col-6">
                <h4>
                    {{ question.category }}
                    <i data-bs-toggle="collapse"
                      :data-bs-target="`#${question.category}`"
                      class="far fa-info-circle text-primary"
                      style="cursor: pointer;"
                    ></i>
                </h4>
                <span>{{ question.title }}</span>
                <div :id="`${question.category}`" class="collapse">
                  <small class="text-muted">{{ question.description }}</small>
                </div>
              </div>
              <div class="col-1 offset-4 offset-sm-5">
                <p class="lead fw-bold mb-0">{{ question.average_score }}</p>
              </div>
          </div>
        </template>
      </div>
    </div>
    <p class="text-center pt-3 pb-3" v-else>
      No Learner Quality Scores to show.
    </p>
  </fragment>
</template>

<script>
import { http } from '@/utils'

export default {
  components: {
  },

  props: {
    provider: {
      type: Object,
      required: false,
      default: null
    },
    course: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      questions: []
    }
  },

  async created () {
    let url = `providers/${this.provider.id}/average-questions-scores`
    if (this.course && this.course.id != null) {
      url = url + '?course_id=' + this.course.id
    }
    const response = await http.get(url)
    this.questions = response.data.data.questions
  },

  computed: {
    learnerQualityScore () {
      if (this.course.learner_quality_score !== null) {
        return this.course.learner_quality_score + ' / 10'
      }
      return '-'
    },

    courseParticipantsCount () {
      if (this.course.course_participant_count) {
        return this.course.course_participant_count
      }
      return '-'
    },

    providerAverage () {
      if (this.provider.provider_average !== undefined && this.provider.provider_average !== null) {
        return this.provider.provider_average + ' / 10'
      }
      return '-'
    },

    providerParticipantsCount () {
      if (this.provider.participant_count) {
        return this.provider.participant_count
      }
      return '-'
    }
  }
}
</script>
