<template>
  <div class="mb-3">
    <p class="form-label">Speakers</p>

    <validation-observer
      disabled
      tag="form"
      class="card"
      @submit.prevent
      ref="speakerValidation"
    >
      <ul class="list-data no-links no-hover has-button">
        <li
          v-for="(speaker, index) in speakers"
          :key="`speaker-${index}`"
        >
          {{ speaker }}
          <button type="button" class="btn btn-sm" @click="removeSpeaker(index)">
            <i class="far fa-trash-alt"></i>
          </button>
        </li>
      </ul>
      <div class="card-body pt-0 p-2 text-end">
        <div class="row gx-2">
          <validation-provider
            tag="div"
            class="col"
            name="speaker name"
            rules="required"
            mode="passive"
          >
            <c-input
              class="form-control-sm"
              placeholder="Speaker"
              v-model="newSpeakerName"
            />
          </validation-provider>
          <div class="col-auto">
            <c-button small @click="addSpeaker" type="submit" validate>
              <i class="far fa-plus"></i> Speaker
            </c-button>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  data () {
    return {
      newSpeakerName: null
    }
  },

  computed: {
    speakers: sync('courses/course@speakers')
  },

  methods: {
    removeSpeaker (index) {
      const speakers = [...this.speakers]

      speakers.splice(index, 1)

      this.speakers = speakers
    },

    addSpeaker () {
      this.speakers = [...this.speakers, this.newSpeakerName]

      this.newSpeakerName = null
      this.$refs.speakerValidation.reset()
    }
  }
}
</script>
