<template>
  <fragment>
    <div class="side-sheet side-sheet-filters overflow-auto" :class="[cols, { show: sidesheetOpen }]">
      <div class="sidesheet-inner overflow-auto">
        <vue-scroll>
          <div class="card-body">
            <h2>Search Filters</h2>

            <button type="button" class="btn btn-sidesheet-show" @click="$emit('close')">
              <i class="far fa-times"></i>
            </button>

            <div class="search-filter" v-if="onStatus">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-check-circle"></i>
                </div>
                <div class="col">
                  <label class="form-label">Status</label>

                  <status-select v-model="filterStatus"/>
                </div>
              </div>
            </div>

            <div class="search-filter" v-if="onKeywords">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-search"></i>
                </div>
                <div class="col">
                  <label class="form-label">Keywords</label>

                  <c-input type="text" v-model="filterKeywords"/>
                </div>
              </div>
            </div>

            <div class="search-filter" v-if="onStartDateRange">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-calendar-alt"></i>
                </div>
                <div class="col">
                  <label class="form-label">When</label>

                  <input-date-range utc v-model="filterStartDate"/>
                </div>
              </div>
            </div>

            <div class="search-filter" v-if="onType">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-chalkboard-teacher"></i>
                </div>
                <div class="col">
                  <label class="form-label">Delivery Method</label>

                  <multiselect
                    :options="typeOptions"
                    label="label"
                    :show-labels="false"
                    :preselect-first="true"
                    :allow-empty="false"
                    v-model="filterType"
                  />
                </div>
              </div>
            </div>

            <div class="search-filter" v-if="onLocations">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-map-marker-alt"></i>
                </div>
                <div class="col">
                  <label class="form-label">Location</label>

                  <course-location-select v-model="filterLocation"/>
                </div>
              </div>

              <div class="selection">
                <div class="chip chip-primary" v-for="(location, index) in localFilters.locations" :key="location">
                  <span class="text-truncate">{{ location }}</span>
                  <button type="button" class="remove-chip" @click="removeSelectedLocation(index)">
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="search-filter" v-if="onAccreditationBody">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fa fa-fw fa-badge-check"></i>
                </div>
                <div class="col">
                  <label class="form-label">Accreditation Body</label>

                  <accreditation-body-select v-model="filterAccreditationBody" />
                </div>
              </div>

              <div class="selection">
                <div class="chip chip-primary" v-for="(ab, index) in localFilters.accreditationbodies" :key="ab">
                  <span class="text-truncate">{{ ab }}</span>
                  <button
                    type="button"
                    class="remove-chip"
                    @click="removeSelectedAccreditationBody(index)"
                  >
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="search-filter" v-if="onAccreditationBody">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon" style="width: 1.25rem;"></i>
                </div>
                <div class="col">
                  <div class="form-check form-switch">
                    <input type="checkbox" v-model="localFilters.accredited_only" class="form-check-input" />
                    <label class="form-label">Show Accredited Only</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="search-filter" v-if="onProfessions">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-user-tie"></i>
                </div>
                <div class="col mb-1">
                  <div class="row">
                    <div class="col d-flex align-items-center">
                      <p class="form-label">Professions</p>
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-topic-select btn-outline-primary"
                        v-c-modal:professions
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="selection">
                <div
                  class="chip chip-primary ms-1"
                  v-for="({ id, name }, index) in filterProfessionNames"
                  :key="`topic-${index}`"
                >
                  <span class="text-truncate">{{ name }}</span>
                  <button type="button" class="remove-chip" @click="removeSelectedProfession(id)">
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="search-filter" v-if="onPrimaryFields">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-hospital-user"></i>
                </div>
                <div class="col mb-1">
                  <div class="row">
                    <div class="col d-flex align-items-center">
                      <p class="form-label">Primary Fields</p>
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-topic-select btn-outline-primary"
                        v-c-modal:primaryFields
                        :disabled="onProfessions && !filterProfessions.length"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="selection">
                <div
                  class="chip chip-primary ms-1"
                  v-for="({ id, name }, index) in filterPrimaryFieldNames"
                  :key="`primary-field-${index}`"
                >
                  <span class="text-truncate">{{ name }}</span>
                  <button type="button" class="remove-chip" @click="removeSelectedPrimaryField(id)">
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="search-filter" v-if="onTopics">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-books-medical"></i>
                </div>
                <div class="col mb-1">
                  <div class="row">
                    <div class="col d-flex align-items-center">
                      <p class="form-label">Topics</p>
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-topic-select btn-outline-primary"
                        v-c-modal:topics
                        :disabled="onProfessions && !filterProfessions.length"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="selection">
                <div class="chip chip-primary ms-1" v-for="({ id, name }, index) in filterTopicNames" :key="`topic-${index}`">
                  <span class="text-truncate">{{ name }}</span>
                  <button type="button" class="remove-chip" @click="removeSelectedTopic(id)">
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="search-filter" v-if="onProviders">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-university"></i>
                </div>
                <div class="col">
                  <label class="form-label">Training Provider</label>
                  <provider-select multiple v-model="filterProviders"/>
                </div>
              </div>

              <div class="selection">
                <div class="chip chip-primary ms-1" v-for="(provider, index) in filterProviderNames" :key="`provider-${index}`">
                  <span class="text-truncate">{{ provider.acronym ? provider.acronym : provider.name }}</span>
                  <button type="button" class="remove-chip" @click="removeSelectedProvider(index)">
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary w-100" @click="submitFilters">Apply Filters</button>
          </div>
        </vue-scroll>
      </div>
    </div>

    <select-professions-drawer
      v-if="onProfessions"
      v-model="filterProfessions"
      ref="professions"
    />

    <select-primary-fields-drawer
      v-if="onPrimaryFields"
      v-model="filterPrimaryFields"
      ref="primaryFields"
      :professions="filterProfessions"
    />

    <select-topics-drawer
      v-if="onTopics"
      v-model="filterTopics"
      ref="topics"
      :professions="filterProfessions"
    />
  </fragment>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { debounce, toNumber, cloneDeep } from 'lodash'
import InputDateRange from '@/components/forms/InputDateRange.vue'
import CourseLocationSelect from '@/components/courses/CourseLocationSelect.vue'
import AccreditationBodySelect from '@/components/courses/AccreditationBodySelect.vue'
import StatusSelect from '@/components/courses/StatusSelect.vue'
import ProviderSelect from '@/components/providers/ProviderSelect.vue'
import SelectTopicsDrawer from '@/components/topics/SelectTopicsDrawer.vue'
import SelectProfessionsDrawer from '@/components/professions/SelectProfessionsDrawer.vue'
import SelectPrimaryFieldsDrawer from '@/components/primary-fields/SelectPrimaryFieldsDrawer.vue'

export default {
  components: {
    InputDateRange,
    CourseLocationSelect,
    AccreditationBodySelect,
    StatusSelect,
    ProviderSelect,
    SelectTopicsDrawer,
    SelectProfessionsDrawer,
    SelectPrimaryFieldsDrawer,
  },

  props: {
    cols: String,
    filters: Object,
    sidesheetOpen: Boolean,
    onStatus: {
      required: false,
      type: Boolean,
      default: false
    },
    onKeywords: {
      required: false,
      type: Boolean,
      default: false
    },
    onStartDateRange: {
      required: false,
      type: Boolean,
      default: false
    },
    onType: {
      required: false,
      type: Boolean,
      default: false
    },
    onLocations: {
      required: false,
      type: Boolean,
      default: false
    },
    onAccreditationBody: {
      required: false,
      type: Boolean,
      default: false
    },
    onTopics: {
      required: false,
      type: Boolean,
      default: false
    },
    onProfessions: {
      required: false,
      type: Boolean,
      default: false
    },
    onPrimaryFields: {
      required: false,
      type: Boolean,
      default: false
    },
    onProviders: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  model: {
    prop: 'filters'
  },

  data () {
    return {
      typeOptions: [
        { label: 'Any', value: null },
        { label: 'In Person', value: 'in_person' },
        { label: 'Online Course', value: 'online' },
        { label: 'Online On Demand', value: 'on_demand' }
      ],
      localFilters: null
    }
  },

  async created () {
    this.localFilters = cloneDeep(this.filters)
    await this.getProfessions().catch(() => {})
  },

  computed: {
    providers: get('lists/providers/providers'),
    topics: get('lists/topics/topics'),
    professions: get('lists/professions/professions'),
    primaryFields: get('lists/primaryFields/primaryFields'),

    filterProviderNames () {
      return this.providers.filter(({ id }) => this.filterProviders.includes(id)).map((provider) => {
        return { name: provider.name, acronym: provider.provider_acronym }
      })
    },

    filterTopicNames () {
      return this.topics
        .reduce((topics, topic) => [...topics, topic, ...topic.children], [])
        .filter(({ id }) => this.filterTopics.includes(id))
    },

    filterProfessionNames () {
      return this.professions
        .filter(({ id }) => this.filterProfessions.includes(id))
    },

    filterPrimaryFieldNames () {
      return this.primaryFields
        .filter(({ id }) => this.filterPrimaryFields.includes(id))
    },

    filterStatus: {
      get () {
        return this.localFilters.status
      },

      set (value) {
        this.localFilters.status = value
      }
    },

    filterKeywords: {
      get () {
        return this.localFilters.keywords
      },

      set: debounce(function (value) {
        this.localFilters.keywords = value
      }, 750)
    },

    filterStartDate: {
      get () {
        return { startDate: this.localFilters.start_date_from, endDate: this.localFilters.start_date_to }
      },

      set (value) {
        this.localFilters.start_date_from = value?.startDate ?? null
        this.localFilters.start_date_to = value?.endDate ?? null
      }
    },

    filterType: {
      get () {
        return this.typeOptions.find(({ value }) => value === this.localFilters.type)
      },

      set ({ value }) {
        this.localFilters.type = value
      }
    },

    filterLocation: {
      get () {
        return this.localFilters.locations
      },

      set (value) {
        this.localFilters.locations = value
      }
    },

    filterAccreditationBody: {
      get () {
        return this.localFilters.accreditationbodies
      },

      set (value) {
        this.localFilters.accreditationbodies = value
      }
    },

    filterTopics: {
      get () {
        return this.localFilters.topics.map(toNumber)
      },

      set (value) {
        this.localFilters.topics = value
      }
    },

    filterProfessions: {
      get () {
        return this.localFilters.professions.map(toNumber)
      },

      set (value) {
        this.localFilters.professions = value
      }
    },

    filterPrimaryFields: {
      get () {
        return this.localFilters.primaryFields.map(toNumber)
      },

      set (value) {
        this.localFilters.primaryFields = value
      }
    },

    filterProviders: {
      get () {
        return this.localFilters.providers.map(toNumber)
      },

      set (value) {
        this.localFilters.providers = value
      }
    }
  },

  watch: {
    'filters.past' (newVal) {
      this.localFilters.past = newVal
    },
    'localFilters.professions' (newVal) {
      if (this.topics?.length > 0) {
        this.localFilters.topics = this.topics
          .reduce((topics, topic) => [...topics, topic, ...topic.children], [])
          .filter(({ id }) => {
            return this.localFilters.topics.includes(id)
          })
          .filter((topic) => {
            if (topic.profession_id) {
              return newVal.includes(topic.profession_id)
            }

            return newVal.includes(this.topics.find(({ id }) => id === topic.parent_id).profession_id)
          })
          .map(({ id }) => id)
      }

      this.localFilters.primaryFields = this.primaryFields
        .filter((id) => {
          return this.localFilters.primaryFields.includes(id)
        })
        .filter((primaryField) => {
          return newVal.includes(primaryField.profession_id)
        })
        .map(({ id }) => id)
    },
  },

  methods: {
    getProfessions: call('lists/professions/getProfessions'),

    submitFilters () {
      this.$emit('input', cloneDeep(this.localFilters))
    },

    removeSelectedLocation (index) {
      const locations = [...this.localFilters.locations]

      locations.splice(index, 1)

      this.localFilters.locations = locations
    },

    removeSelectedAccreditationBody (index) {
      const accreditationbodies = [...this.localFilters.accreditationbodies]

      accreditationbodies.splice(index, 1)

      this.localFilters.accreditationbodies = accreditationbodies
    },

    removeSelectedTopic (id) {
      const topics = [...this.filterTopics]
      const index = topics.indexOf(id)

      topics.splice(index, 1)
      this.localFilters.topics = topics
    },

    removeSelectedProfession (id) {
      const professions = [...this.filterProfessions]
      const index = professions.indexOf(id)

      professions.splice(index, 1)
      this.localFilters.professions = professions
    },

    removeSelectedPrimaryField (id) {
      const primaryFields = [...this.filterPrimaryFields]
      const index = primaryFields.indexOf(id)

      primaryFields.splice(index, 1)
      this.localFilters.primaryFields = primaryFields
    },

    removeSelectedProvider (index) {
      const providers = [...this.localFilters.providers]

      providers.splice(index, 1)

      this.localFilters.providers = providers
    }
  }
}
</script>
