<template>
  <multiselect
    placeholder="Select"
    :options="options"
    v-model="locationsSelected"
    multiple
    label="state"
    track-by="state"
    group-values="locations"
    group-label="country"
    group-select
    preselect-first
    :show-labels="false"
    :close-on-select="false"
  >
    <template #tag>
      <span></span>
    </template>
  </multiselect>
</template>

<script>
import { http } from '@/utils'
import { uniq } from 'lodash'

export default {
  props: {
    value: Array
  },

  data () {
    return {
      locations: []
    }
  },

  computed: {
    options () {
      const options = []
      const locations = [...this.locations]

      locations.sort((a, b) => {
        if (a.country === 'Australia' || b.country === 'Australia') {
          return 1
        }

        return -1
      })

      for (const location in locations) {
        const index = options.findIndex(({ country }) => country === locations[location].country)

        if (index === -1) {
          options.push({ country: locations[location].country, locations: [locations[location]] })
        } else {
          options[index].locations.push(locations[location])
        }
      }

      return options
    },

    locationsSelected: {
      get () {
        return this.locations.filter(({ country, state }) => this.value.includes(`${country} / ${state}`))
      },

      set (value) {
        this.$emit('input', uniq(value.map(({ state, country }) => `${country} / ${state}`)))
      }
    }
  },

  async created () {
    const response = await http.get('lists/course-locations')

    this.locations = response.data.data
  }
}
</script>
