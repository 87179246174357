<template>
  <div class="container mt-4">
    <average-score-card :provider="course.provider" :course="course"></average-score-card>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import AverageScoreCard from '@/views/AverageScoreCard.vue'

export default {
  components: {
    AverageScoreCard
  },

  computed: {
    course: get('courses/course'),
    provider: get('providers/provider'),
  }
}
</script>
