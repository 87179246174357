<template>
  <fragment>
    <app-header title="Course Search" />

    <div class="layout-sidesheet row">
      <div class="col main-content">
        <vue-scroll>
          <div class="container">
            <div class="row">
              <div class="col d-flex align-items-center">
                <strong>Search Results</strong>
              </div>
              <div class="col-auto">
                <div class="row">
                  <label class="col-auto col-form-label">Sort By</label>
                  <div class="col">
                    <sort-select v-model="sortSelected" />
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="row">
                  <div class="form-check form-switch">
                    <input type="checkbox" v-model="filters.past" id="showPastEvents" class="form-check-input" />
                    <label for="showPastEvents" class="form-check-label">Show Past Events</label>
                  </div>
                </div>
              </div>
            </div>

            <c-data-list
              :items="courses"
              @load="load"
              class="results-list"
              target-vue-scroll
              remove-default-class
            >
              <template #default="{ item: course }">
                <course-card v-bind="{ course }">
                  <template #bookmark>
                    <bookmark-button v-bind="{ course }" v-c-modal:addLearningPlan="{ course }" />
                    <span class="like">
                      <i class="fad fa-thumbs-up"></i>
                      {{ course.likes_count }}
                    </span>
                  </template>
                </course-card>
              </template>
            </c-data-list>
          </div>

          <slot name="footer" />
        </vue-scroll>
      </div>

      <filters-sidesheet
        cols="col-xl-4 col-xxl-3"
        v-bind="{ sidesheetOpen }"
        @close="sidesheetOpen = false"
        v-model="filters"
        on-keywords
        on-start-date-range
        on-type
        on-locations
        on-professions
        on-primary-fields
        on-topics
        on-providers
        on-accreditation-body
      />
    </div>

    <add-learning-plan-modal
      ref="addLearningPlan"
      v-bind="{ courses }"
      @updated="setCourses"
    />

    <portal to="headerRight">
      <ul class="header-nav justify-content-end">
        <li>
          <button
            type="button"
            class="btn btn-sidesheet-show search navbar-user-link d-xl-none"
            @click="sidesheetOpen = true"
          >
            <i class="fal fa-search"></i> Search
          </button>
        </li>
      </ul>
    </portal>
  </fragment>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { useQueryParamFilters, compareQueryParams } from '@codium/codium-vue'
import FiltersSidesheet from '@/components/courses/FiltersSidesheet.vue'
import CourseCard from '@/components/courses/user/CourseCard.vue'
import SortSelect from '@/components/courses/SortSelect.vue'
import AddLearningPlanModal from '@/components/courses/AddLearningPlanModal.vue'
import BookmarkButton from '@/components/learning-plans/BookmarkButton.vue'

export default {
  mixins: [useQueryParamFilters()],

  components: {
    FiltersSidesheet,
    CourseCard,
    SortSelect,
    AddLearningPlanModal,
    BookmarkButton,
  },

  data () {
    return {
      sidesheetOpen: false,
      filters: {
        keywords: null,
        start_date_from: null,
        start_date_to: null,
        locations: [],
        accreditationbodies: [],
        accredited_only: false,
        providers: [],
        professions: [],
        primaryFields: [],
        topics: [],
        type: null,
        sort: 'plqs',
        order: 'desc',
        past: false
      }
    }
  },

  computed: {
    courses: get('courses/published/courses'),
    bodies: get('users/accreditationBodies'),

    sortSelected: {
      get () {
        return { sort: this.filters.sort, order: this.filters.order }
      },

      set ({ sort, order }) {
        this.filters.sort = sort
        this.filters.order = order
      }
    }
  },

  async created () {
    if (this.$route.params.providerId) {
      this.filters.providers.push(this.$route.params.providerId)
    }

    if (!this.$route.query.professions?.length && this.$user.profession_id) {
      this.filters.professions = [this.$user.profession_id]
    }

    if (!this.$route.query.topics?.length && this.$user?.topics?.length > 0) {
      this.filters.topics = this.$user.topics.map(item => item.id)
    } else if (this.$route.query.topics?.length && typeof this.$route.query.topics === 'object') {
      this.filters.topics = Object.keys(this.$route.query.topics).map(key => this.$route.query.topics[key])
    }
  },

  watch: {
    '$route.query' (query) {
      if (Object.keys(query).length === 0) {
        this.filters = this.$options.data().filters
      }
    },

    queryParams: compareQueryParams(async function (params) {
      await this.getCourses({ params, fresh: true })
    })
  },

  beforeDestroy () {
    this.resetCourses()
  },

  methods: {
    getCourses: call('courses/published/getCourses'),
    setCourses: call('courses/published/setCourses'),
    resetCourses: call('courses/published/resetCourses'),
    getAccreditationBodies: call('users/accreditationBodies/getAccreditationBodies'),

    async load (done) {
      try {
        await this.getCourses({ params: this.queryParams })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
