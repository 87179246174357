<template>
  <fragment>
    <div class="card" v-if="questions.length">
      <div class="card-header d-flex align-items-center justify-content-between">
          <h3 class="card-title">Average Score</h3>
          <p class="mb-0 fw-bold fs-2">
            {{ course ? learnerQualityScore : providerAverage }}
          </p>
      </div>
      <ul class="list-data no-links no-hover">
        <template v-for="score in questions">
          <li :key="`score-${score.category}`">
            <div class="row">
                <div class="col-6 col-lg-12 col-xl-10">
                  <div class="d-flex align-items-center gap-3">
                      <div><i class="far fa-book-open"></i></div>
                      <div>
                        <p class="strong">{{ score.category }}</p>
                      </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 col-xl-2">
                  <p class="text-end fs-3 fw-bold">{{ score.average_score }}</p>
                </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <p class="text-center pt-3 pb-3" v-else>
      No Learner Quality Scores to show.ddd
    </p>
  </fragment>
</template>

<script>
import { http } from '@/utils'

export default {
  components: {
  },

  props: {
    provider: {
      type: Object,
      required: false,
      default: null
    },
    course: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      questions: []
    }
  },

  async created () {
    let url = `providers/${this.provider.id}/average-questions-scores`
    if (this.course && this.course.id != null) {
      url = url + '?course_id=' + this.course.id
    }
    const response = await http.get(url)
    this.questions = response.data.data.questions
  },

  computed: {
    learnerQualityScore () {
      if (this.course.learner_quality_score !== null) {
        return this.course.learner_quality_score + ' / 10'
      }
      return '-'
    },

    providerAverage () {
      if (this.provider.provider_average !== undefined && this.provider.provider_average !== null) {
        return this.provider.provider_average + ' / 10'
      }
      return '-'
    }
  }
}
</script>
