<template>
  <fragment>
    <button type="button" class="btn btn-md btn-primary" v-c-modal:add>Add Course</button>

    <add-course-modal ref="add"/>
  </fragment>
</template>

<script>
import AddCourseModal from './AddCourseModal.vue'

export default {
  components: {
    AddCourseModal
  }
}
</script>
