<template>
  <div>
    <div class="alert bg-white text-primary mb-4">
      Please ensure CPD values are only added for bodies who have accredited
      your course and not an estimated value. Med CPD is not responsible for any
      incorrect values and does not validate these.
    </div>
    <validation-observer tag="div" class="card">
      <ul class="list-data no-links no-hover list-data-course-details">
        <li class="data-heading d-none d-xl-block sticky-top">
          <div class="row">
            <div class="col-xl-course-details">
              Accreditation Body
            </div>
            <div class="col-xl-course-details">
              Educational Activity
            </div>
            <div class="col-xl-course-details">
              Reviewing Performance
            </div>
            <div class="col-xl-course-details">
              Measuring Outcomes
            </div>
            <div class="col-xl-course-details">
              Points
            </div>
            <div class="col-xl-course-details">
              Hours
            </div>
          </div>
        </li>
        <accreditation-value-list-item
          v-for="(accreditationValue, index) in accreditationValues"
          :key="accreditationValue.body.id"
          v-bind="{ accreditationValue, index }"
          @update="updateAccreditationValue"/>
      </ul>
      <div class="card-body bg-white sticky-bottom py-4 text-end">
        <c-button class="btn-md" @click="save" validate>
          Save Changes
        </c-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { toast } from '@chameleon/chameleon-vue'
import { sync, call } from 'vuex-pathify'
import AccreditationValueListItem from '@/components/courses/AccreditationValueListItem.vue'

export default {
  components: {
    AccreditationValueListItem
  },

  computed: {
    accreditationValues: sync('courses/accreditationValues/accreditationValues')
  },

  async created () {
    await this.getAccreditationValues()
  },

  methods: {
    getAccreditationValues: call('courses/accreditationValues/getAccreditationValues'),
    updateAccreditationValues: call('courses/accreditationValues/updateAccreditationValues'),

    async save () {
      await this.updateAccreditationValues(this.accreditationValues.map(({
        educationalActivity,
        reviewingPerformance,
        measuringOutcomes,
        points,
        hours,
        body
      }) => ({
        accreditation_body_id: body.id,
        educational_activity: educationalActivity,
        reviewing_performance: reviewingPerformance,
        measuring_outcomes: measuringOutcomes,
        points: points,
        hours: hours,
      })))

      toast('Accreditation values updated.')
    },

    updateAccreditationValue (accreditationValue) {
      const accreditationValues = [...this.accreditationValues]
      const index = accreditationValues.findIndex(({ body }) => body.id === accreditationValue.body.id)

      accreditationValues.splice(index, 1, accreditationValue)

      this.accreditationValues = accreditationValues
    }
  }
}
</script>
