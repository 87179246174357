<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-8">
          <label class="form-label">Training Provider</label>
          <provider-select v-model="providerSelected" :allow-empty="false"/>
        </div>
        <div class="col-4" v-if="course.published === true">
          <label class="form-label">Review Status</label>
          <div class="input-group">
            <div class="input-group-text">
              <i class="far fa-fw fa-check-circle"></i>
            </div>

            <single-select :options="reviewOptions" v-model="review" :allow-empty="false"/>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <div class="form-check">
          <input
            type="checkbox"
            id="featured-course"
            class="form-check-input"
            v-model="featured"
          />
          <label for="featured-course" class="form-check-label">
            Show on home page
          </label>
        </div>
      </div>
      <div class="text-end">
        <c-button class="btn-md" @click="save">
          Save Changes
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import { toast } from '@chameleon/chameleon-vue'
import ProviderSelect from '@/components/providers/ProviderSelect.vue'
import SingleSelect from '@/components/forms/SingleSelect.vue'

export default {
  components: {
    SingleSelect,
    ProviderSelect
  },

  data () {
    return {
      reviewOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' },
      ],
    }
  },

  computed: {
    course: get('courses/course'),
    review: sync('courses/course@review'),
    provider: sync('courses/course@provider'),
    featured: sync('courses/course@featured'),
    rejectionReason: sync('courses/course@rejection_reason'),
    courseSettings: get('courses/courseSettings'),

    providerSelected: {
      get () {
        return { id: this.provider.id, name: this.provider.name }
      },

      async set ({ id }) {
        const provider = await this.getProvider(id)

        this.provider = provider
      }
    }
  },

  methods: {
    getProvider: call('providers/getProvider'),
    updateCourseSettings: call('courses/updateCourseSettings'),
    openCourseRejectionModal: call('courses/openCourseRejectionModal'),

    async save () {
      try {
        if (this.course.rejected === false && this.review === 'rejected') {
          this.rejectionReason = await this.openCourseRejectionModal()
        }

        await this.updateCourseSettings({
          id: this.course.id,
          payload: this.courseSettings
        })

        toast('Course settings have been saved.')
      } catch {
        //
      }
    }
  }
}
</script>
