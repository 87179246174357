<template>
  <fragment>
    <app-header title="Course Details" />

    <c-horizontal-nav>
      <c-horizontal-nav-link :to="{ name: 'courses.show' }" exact>
        Course Details
      </c-horizontal-nav-link>
      <c-horizontal-nav-link :to="{ name: 'courses.show.accreditation-values' }">
        CPD Value
      </c-horizontal-nav-link>
      <c-horizontal-nav-link :to="{ name: 'courses.show.revisions' }">
        Revision History
      </c-horizontal-nav-link>
      <c-horizontal-nav-link :to="{ name: 'courses.show.learner-quality-score' }">
        Learner Quality Score
      </c-horizontal-nav-link>
    </c-horizontal-nav>

    <div class="container mt-4">
      <pending-review-alert v-if="course.pending_admin_review"/>

      <router-view v-if="course.id" />
    </div>

    <slot name="footer"/>

    <portal to="modals">
      <reject-reason-modal/>
    </portal>
  </fragment>
</template>

<script>
import { call, get } from 'vuex-pathify'
import PendingReviewAlert from '@/components/courses/admin/PendingReviewAlert.vue'
import RejectReasonModal from '@/components/courses/admin/RejectReasonModal.vue'

export default {
  props: {
    courseId: [Number, String]
  },

  components: {
    PendingReviewAlert,
    RejectReasonModal
  },

  computed: {
    course: get('courses/course')
  },

  watch: {
    courseId: {
      async handler (id) {
        await this.getCourse({ id })
      },
      immediate: true
    }
  },

  beforeDestroy () {
    this.resetCourse()
  },

  methods: {
    getCourse: call('courses/getCourse'),
    resetCourse: call('courses/resetCourse'),
  }
}
</script>
