<template>
  <div>
    <slot v-if="map"/>
  </div>
</template>

<script>
import { maps } from '@/utils/google-maps'

export default {
  model: {
    prop: 'map',
    event: 'loaded'
  },

  provide () {
    return {
      map: () => this.map
    }
  },

  props: {
    map: Object,

    zoom: {
      required: false,
      type: Number,
      default: 10
    },

    center: {
      type: Object,
      default () {
        return { lat: -34.922, lng: 138.569 }
      }
    },

    disableStreetView: Boolean
  },

  async mounted () {
    if (!maps.done) {
      await maps.load()
    }

    const map = new window.google.maps.Map(this.$el, {
      center: { lat: -34.922, lng: 138.569, ...this.center },
      streetViewControl: !this.disableStreetView,
      gestureHandling: 'cooperative',
      zoom: this.zoom
    })

    this.$emit('loaded', map)
  }
}
</script>
