import { maps } from '@/utils/google-maps'

export default {
  name: 'GoogleMapMarker',

  inject: ['map'],

  props: {
    value: Object,
    place: Object,
    latitude: Number,
    longitude: Number,
    draggable: Boolean
  },

  data () {
    return {
      marker: null,
      dragging: false
    }
  },

  computed: {
    options () {
      let position

      if (this.place && this.place.geometry) {
        position = this.place.geometry.location
      } else if (this.latitude && this.longitude) {
        position = { lat: this.latitude, lng: this.longitude }
      }

      return {
        position,
        draggable: this.draggable,
        map: this.map()
      }
    }
  },

  async mounted () {
    if (!maps.done) {
      await maps.load()
    }

    this.marker = new window.google.maps.Marker(this.options)

    this.marker.addListener('dragstart', () => {
      this.dragging = true
    })

    this.marker.addListener('position_changed', () => {
      if (this.dragging) {
        return
      }

      this.$emit('updated', this.marker)
    })

    this.marker.addListener('dragend', () => {
      this.dragging = false

      this.$emit('dragged', this.marker)
    })

    this.$emit('input', this.marker)
  },

  watch: {
    options () {
      this.marker.setOptions(this.options)
    }
  },

  render: () => null
}
