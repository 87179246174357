<template>

      <c-data-list
        :items="revisions"
        @load="load"
        class="list-data-history list-data-xs-full no-links no-hover"
      >
        <template #default="{ item: revision }">
          <revision-list-item v-bind="{ revision }"/>
        </template>
      </c-data-list>
</template>

<script>
import { get, call } from 'vuex-pathify'
import RevisionListItem from '@/components/courses/admin/RevisionListItem.vue'

export default {
  components: {
    RevisionListItem
  },

  computed: {
    revisions: get('courses/revisions/revisions')
  },

  beforeDestroy () {
    this.resetRevisions()
  },

  methods: {
    getRevisions: call('courses/revisions/getRevisions'),
    resetRevisions: call('courses/revisions/resetRevisions'),

    async load (done) {
      try {
        await this.getRevisions()

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
