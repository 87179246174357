import Provider from './provider/CoursesShow.vue'
import Admin from './admin/CoursesShow.vue'
import User from './user/CoursesShow.vue'

const components = {
  provider: Provider,
  admin: Admin,
  user: User,
}

export default {
  functional: true,

  render (h, context) {
    const { role } = context.parent.$store.state.auth.user

    return h(components[role.name], context.data, context.children)
  }
}
