<template>
  <multiselect
    placeholder="Select"
    :options="accreditation_bodies"
    v-model="accreditationBodySelected"
    multiple
    label="name"
    track-by="id"
    :disabled="disabled"
  >
    <template #tag>
      <span></span>
    </template>
  </multiselect>
</template>

<script>
import { http } from '@/utils'
import { uniq } from 'lodash'
export default {
  props: {
    value: {
      required: true,
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  data () {
    return {
      accreditation_bodies: []
    }
  },

  computed: {
    accreditationBodySelected: {
      get () {
        return this.accreditation_bodies.filter((body) => this.value.includes(body.name) || this.value.includes(body.body_acronym))
      },

      set (value) {
        this.$emit('input', uniq(value.map((body) => body.body_acronym ? body.body_acronym : body.name)))
      }
    }
  },

  async created () {
    const response = await http.get('lists/accreditation-bodies')

    this.accreditation_bodies = response.data.data
  }
}
</script>
