<template>
  <div class="modal modal-get-in-touch show" id="modal-thing" tabindex="-1" aria-hidden="true" >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <validation-observer tag="div" class="modal-content" @submit.native.prevent>
        <!-- header -->
        <div class="modal-header">
          <h1 class="modal-title">Get In Touch</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
        </div>

        <!-- body -->
        <div class="modal-body" >
          <div class="mb-3">
            <label class="form-label">Name</label>
            <p class="form-control-plaintext">{{ $user.name }}</p>
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <p class="form-control-plaintext">{{ $user.email }}</p>
          </div>
          <!-- subject -->
          <validation-provider tag="div" name="subject" rules="required" class="mb-3" >
            <label class="form-label">Subject</label>
            <c-input v-model="subject"/>
            <c-help-block />
          </validation-provider>
          <!-- message -->
          <validation-provider tag="div" class="mb-3" name="message" rules="required" >
            <label class="form-label">Message</label>
            <c-textarea type="text" :rows="6" v-model="message" />
            <c-help-block />
          </validation-provider>
        </div>

        <!-- footer -->
        <div class="modal-footer">
          <c-button variant="primary" @click="submit" validate>
            <i class="fa fa-paper-plane"></i> Send
          </c-button>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { toast, CModal } from '@chameleon/chameleon-vue'
import { helperComputed } from '@/vuex/modules/courses'
import { http } from '@/utils'

export default {
  name: 'GetInTouchModal',
  extends: CModal,

  data () {
    return {
      subject: null,
      message: null,
    }
  },

  computed: {
    ...helperComputed,
  },

  methods: {
    async submit () {
      var payload = {
        subject: this.subject,
        message: this.message,
      }
      await http.post(`courses/${this.course.id}/feedback`, payload)
      toast('Email sent', 'success')
      this.close()
    }
  }
}
</script>
