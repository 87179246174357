<template>
  <multiselect
    placeholder="Select"
    :options="options"
    label="label"
    v-model="statusSelected"
    :preselect-first="true"
    :show-labels="false"
    :allow-empty="false"
  />
</template>

<script>
export default {
  props: {
    value: String
  },

  data () {
    return {
      options: [
        { label: 'All', value: null },
        { label: 'Draft', value: 'draft' },
        { label: 'Pending Review', value: 'pending' },
        { label: 'Published', value: 'published' },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Archived', value: 'archived' },
      ]
    }
  },

  computed: {
    statusSelected: {
      get () {
        return this.options.find(({ value }) => value === this.value)
      },

      set ({ value }) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
