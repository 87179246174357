<template>
  <article class="card card-course card-course-horizontal card-course-provider">
    <router-link :to="{ name: 'courses.show', params: { courseId: course.id } }" class="featured-container">
      <img class="featured" :src="featuredImageUrl" width="1280" height="720" />
      <span class="featured-topic" v-if="course.featured_topic_name">
        {{ course.featured_topic_name }}
      </span>
    </router-link>

    <status-label v-bind="{ status: course.status }" />

    <slot name="like-btn" />

    <span
      class="previous-marker"
      title="Past Event"
      v-c-tooltip
      v-if="course.previous"
    >
      <i class="far fa-history" />
    </span>

    <router-link :to="{ name: 'courses.show', params: { courseId: course.id } }" class="card-body">
      <h2>{{ course.name }}</h2>

      <learner-quality-score :course="course"></learner-quality-score>

      <ul class="tag-list">
        <li v-if="course.duration">
          <span class="tag tag-secondary">{{ course.duration.formatted }}</span>
        </li>
        <li v-if="course.location">
          <span class="tag tag-primary">{{ course.location }}</span>
        </li>
        <li v-if="course.start_date">
          <span class="tag tag-primary">{{ startDate }}</span>
        </li>
        <li>
          <span class="tag tag-primary">{{ price }}</span>
        </li>
      </ul>

      <p>{{ course.short_description }}</p>
    </router-link>
  </article>
</template>

<script>
import { placeholders } from '@/utils/placeholders'
import StatusLabel from '../StatusLabel.vue'
import LearnerQualityScore from '../LearnerQualityScore.vue'

export default {
  components: {
    StatusLabel,
    LearnerQualityScore
  },

  props: {
    course: Object
  },

  computed: {
    featuredImageUrl () {
      return this.course.featured_image_url ?? placeholders.large
    },

    startDate () {
      if (this.course.type === 'on_demand') {
        return 'On-Demand'
      }
      return (new Date(this.course.start_date)).toLocaleDateString('en-AU')
    },

    price () {
      const price = parseFloat(this.course.price)
      if (price === 0 || this.course.price === null) {
        return 'Free'
      }

      return `$${price.toFixed(2)}`
    }

  },
}
</script>
