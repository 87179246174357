<template>
  <validation-observer tag="form" class="modal" aria-hidden="true" @submit.prevent="save">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Add Course</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body overflow-visible">
          <div class="mb-3" v-if="$isAn('admin')">
            <label class="form-label">Training Provider</label>

            <provider-select :allow-empty="false" v-model="providerSelected"/>
          </div>

          <div class="row mt-2">
            <validation-provider tag="div" class="col" name="course name" vid="name" rules="required">
              <label class="visually-hidden">Course Name</label>
              <c-input
                type="text"
                placeholder="Course Name"
                v-model="payload.name"
              />
              <c-help-block/>
            </validation-provider>
            <div class="col-auto">
              <c-button
                type="button"
                @click="save"
                validate
              >
                Create
              </c-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { CModal, toast } from '@chameleon/chameleon-vue'
import { get, call } from 'vuex-pathify'
import ProviderSelect from '@/components/providers/ProviderSelect.vue'

export default {
  extends: CModal,

  components: {
    ProviderSelect
  },

  data () {
    return {
      payload: {
        provider_id: null,
        name: null
      }
    }
  },

  computed: {
    provider: get('providers/provider'),
    providers: get('lists/providers/providers'),

    providerSelected: {
      get () {
        return this.providers.find(({ id }) => id === this.payload.provider_id)
      },

      set ({ id }) {
        this.payload.provider_id = id
      }
    }
  },

  mounted () {
    this.addEventListener('open', () => {
      if (this.$isA('provider') && this.provider.id) {
        this.payload.provider_id = this.provider.id
      }
    })
  },

  methods: {
    storeCourse: call('courses/storeCourse'),

    async save () {
      try {
        const course = await this.storeCourse(this.payload)

        this.close()

        this.$router.push({ name: 'courses.show', params: { courseId: course.id } })
      } catch (error) {
        if (error.response?.status === 400 && error.response.data.message === 'mismatch_provider_id') {
          toast('You do not have permission to create a course for that provider.', 'danger')
        } else {
          throw error
        }
      }
    }
  }
}
</script>
