<template>
  <fragment>
    <validation-observer tag="div" class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-8">
            <validation-provider
              tag="div"
              name="name"
              class="mb-3"
              rules="required"
            >
              <label class="form-label">Course Name</label>

              <c-input type="text" v-model="stateName" />

              <c-help-block />
            </validation-provider>

            <validation-provider
              tag="div"
              name="course type"
              vid="type"
              class="mb-3"
              rules="required"
            >
              <label class="form-label">Course Type</label>

              <single-select :options="typeOptions" v-model="stateType" />

              <c-help-block />
            </validation-provider>

            <validation-provider
              tag="div"
              name="book now link"
              vid="booking_url"
              class="mb-3"
            >
              <label class="form-label">Book Now Link</label>

              <c-input type="text" v-model="stateBookingUrl" />

              <c-help-block />
            </validation-provider>

            <validation-provider
              tag="div"
              name="short description"
              vid="short_description"
              class="mb-3"
              rules="max:280"
            >
              <label class="form-label">Short Description</label>

              <c-textarea v-model="stateShortDescription" />

              <c-help-block />

              <div class="row gx-2 form-text">
                <div class="col">
                  This is shown to users on course listings and search
                </div>
                <div class="col-auto">
                  {{ shortDescriptionCharacters }} of 280 characters
                </div>
              </div>
            </validation-provider>

            <validation-provider tag="div" class="mb-3" name="description">
              <label class="form-label">Long Description</label>

              <quill-textarea v-model="stateDescription" />

              <c-help-block />
            </validation-provider>

            <validation-provider tag="div" class="mb-3" name="profession" vid="professions" rules="required">
              <label class="form-label">Profession</label>
                <profession-select
                  v-model="selectedProfessions"
                  multiple
                  hide-tag
                />
                <c-help-block />

                <ul class="list-data list-data-primary-fields no-links no-hover has-button">
                  <li v-for="item in stateProfessions" :key="item.id" v-bind="{ item }">
                    {{ item.name }}
                    <button type="button" @click="removeProfession(item)" class="btn btn-sm text-danger">
                      <i class="far fa-times"></i>
                    </button>
                  </li>
                </ul>
            </validation-provider>

            <validation-provider tag="div" class="mb-3" name="primary field" vid="primary_fields">
              <label class="form-label">Primary Fields</label>
              <primary-field-select
                v-model="statePrimaryFields"
                :checkBoxChecked="stateDoNotShowToStudents"
                placeholder="Add Primary Fields"
                :disabled="!stateProfessions.length"
                :professions="stateProfessions"
              />

              <c-help-block />
              <ul class="list-data list-data-primary-fields no-links no-hover has-button">
                <li v-for="item in statePrimaryFields" :key="`primary-field-${item.id}`" v-bind="{ item }">
                  {{ item.name }}
                  <button type="button" @click="removePrimaryField(item)" class="btn btn-sm text-danger">
                    <i class="far fa-times"></i>
                  </button>
                </li>
              </ul>
            </validation-provider>

            <course-form-topics :profession-ids="stateProfessions.map((profession) => { return profession.id })" />

            <course-form-speakers />

            <template v-if="inPersonCourse">
              <h2 class="h3 mt-4">Location</h2>

              <div class="mb-3">
                <label class="visually-hidden">Address</label>

                <google-map-autocomplete-input
                  v-bind="{ map }"
                  :value="course.address"
                  @input="updateAddress"
                />
              </div>

              <google-map
                disable-street-view
                v-model="map"
                class="course-map"
                :center="mapCenter"
                :zoom="mapZoom"
              >
                <google-map-marker
                  :latitude="course.address.latitude"
                  :longitude="course.address.longitude"
                />
              </google-map>
            </template>
          </div>
          <div class="col-xl-4">
            <div class="mb-3">
              <p class="form-label">Featured Image</p>

              <template v-if="course.featured_image_url">
                <img
                  :src="course.featured_image_url"
                  width="1280"
                  height="720"
                  class="featured-thumbnail"
                />

                <c-button
                  variant="link"
                  class="text-danger px-0 py-0 text-decoration-none"
                  @click="deleteFeaturedImage"
                  >Delete Image</c-button
                >
              </template>

              <c-drop-files
                v-else
                :max-files="1"
                :max-size="5"
                upload
                :destination="courseImageUploadUrl"
                @uploaded="featuredImageUploaded"
              >
                <template #note>
                  Maximum 5MB. File types: jpg, gif, png. Optimal image dimension: 1920 x 1080 pixels
                </template>
              </c-drop-files>
            </div>

            <template v-if="inPersonCourse || onlineCourse">
              <h2 class="h3 mt-4">Date</h2>

              <div class="mb-3">
                <label class="form-label">Start Date</label>
                <validation-provider slim name="start date" vid="start_date">
                  <c-input-group>
                    <div class="input-group-text">
                      <i class="fal fa-fw fa-calendar-day"></i>
                    </div>

                    <c-date v-model="stateStartDate" allow-input />
                  </c-input-group>

                  <c-help-block />
                </validation-provider>
              </div>

              <div class="mb-3">
                <label class="form-label">Start Time</label>
                <validation-provider slim name="start time" vid="start_time">
                  <c-input-group>
                    <div class="input-group-text">
                      <i class="fal fa-fw fa-clock"></i>
                    </div>

                    <c-time v-model="stateStartTime" allow-input />
                  </c-input-group>

                  <c-help-block />
                </validation-provider>
              </div>

              <div class="mb-3">
                <label class="form-label">Timezone</label>
                <validation-provider slim name="timezone" rules="required">
                  <c-input-group>
                    <div class="input-group-text">
                      <i class="fal fa-fw fa-globe-asia"></i>
                    </div>

                    <c-timezone-select
                      v-model="stateTimezone"
                      :allow-empty="false"
                    />
                  </c-input-group>

                  <c-help-block />
                </validation-provider>
              </div>
            </template>

            <div class="mb-3">
              <label class="form-label">Duration</label>
              <validation-provider slim name="duration">
                <c-input-group>
                  <div class="input-group-text">
                    <i class="fal fa-fw fa-stopwatch"></i>
                  </div>

                  <div class="col">
                    <c-input type="text" v-model="stateDurationValue" />
                  </div>

                  <div class="col">
                    <single-select
                      :options="durationOptions"
                      v-model="stateDurationUnit"
                    />
                  </div>
                </c-input-group>

                <c-help-block />
              </validation-provider>
            </div>

            <h2 class="h3 mt-4">Price</h2>

            <div class="mb-3">
              <label class="form-label">Price</label>
              <div class="input-group">
                <div class="input-group-text">
                  <i class="fal fa-fw fa-dollar-sign"></i>
                </div>
                <c-number v-model="statePrice" :decimals="true" />
                <single-select class="border-start" :options="currencies" v-model="stateCurrency" :internalSearch="true" />
              </div>
            </div>

            <h2 class="h3 mt-4">Questionnaire URL</h2>

            <div class="mb-3">
              <div class="input-group">
                <input class="form-control"
                      v-on:focus="$event.target.select()"
                      ref="questionnaireUrl"
                      readonly
                      :value="stateUrl"/>
                <button @click="copy" type="button" class="btn btn-primary">{{copyLabel}}</button>
              </div>
            </div>

            <h2 class="h3 mt-4">Restrictions</h2>

            <div class="mb-3">
              <div class="input-group">
                <c-checkbox class="form-check-inline" v-model="stateDoNotShowToStudents">Do not show to Students</c-checkbox>
              </div>
            </div>

            <div class="mb-3">
              <div class="input-group">
                <c-checkbox class="form-check-inline" v-model="stateAhpraVerificationRequired">AHPRA Verification Required</c-checkbox>
              </div>
            </div>

            <h2 class="h3 mt-4">Location Restrictions</h2>

            <p class="text-muted small mb-2">
              This course will only be shown to medical professionals within the locations selected.
            </p>

            <div class="mb-3">
              <validation-provider tag="div" class="mb-3" name="locations">
                <locations-select v-model="stateLocations" placeholder="Add Locations"/>
                <c-help-block />
                <ul class="list-data list-data-locations no-links no-hover has-button">
                  <li v-for="item in stateLocations" :key="item.id" v-bind="{ item }">
                    {{ item.name }}
                    <button type="button" @click="removeLocation(item)" class="btn btn-sm text-danger">
                      <i class="far fa-times"></i>
                    </button>
                  </li>
                </ul>
              </validation-provider>
            </div>

            <h2 class="h3 mt-4">Profession Restrictions</h2>

            <div class="mb-3">
              <validation-provider tag="div" class="mb-3" name="profession" vid="restriction_profession_id">
                <profession-select
                  v-model="selectedRestrictionProfessions"
                  multiple
                  hide-tag
                />
                <c-help-block />

                <ul class="list-data list-data-primary-fields no-links no-hover has-button">
                  <li v-for="item in stateRestrictionProfessions" :key="item.id" v-bind="{ item }">
                    {{ item.name }}
                    <button type="button" @click="removeRestrictionProfession(item)" class="btn btn-sm text-danger">
                      <i class="far fa-times"></i>
                    </button>
                  </li>
                </ul>
              </validation-provider>
            </div>

            <h2 class="h3 mt-4">Primary Field Restrictions</h2>

            <p class="text-muted small mb-2">This course will only be shown to medical professionals within the primary fields selected.</p>

            <div class="mb-3">
              <validation-provider tag="div" class="mb-3" name="primary field">
                <primary-field-select
                  v-model="stateRestrictionPrimaryFields"
                  :checkBoxChecked="stateDoNotShowToStudents"
                  placeholder="Add Primary Fields"
                  :disabled="!stateRestrictionProfessions.length"
                  :professions="stateRestrictionProfessions"
                />
                <c-help-block />
                <ul class="list-data list-data-primary-fields no-links no-hover has-button">
                  <li v-for="item in stateRestrictionPrimaryFields" :key="item.id" v-bind="{ item }">
                    {{ item.name }}
                    <button type="button" @click="removePrimaryField(item)" class="btn btn-sm text-danger">
                      <i class="far fa-times"></i>
                    </button>
                  </li>
                </ul>
              </validation-provider>
            </div>

            <h2 class="h3 mt-4">Specific Qualifications Restrictions</h2>

            <p class="text-muted small mb-2">This course will only be shown to medical professionals with the qualifications selected.</p>

            <div class="mb-3">
              <validation-provider tag="div" class="mb-3" name="qualifications">
                <qualifications-select
                  v-model="stateQualifications"
                  :checkBoxChecked="stateDoNotShowToStudents"
                  placeholder="Add Qualifications"
                  :professions="selectedRestrictionProfessions"
                  :disabled="!selectedRestrictionProfessions.length"
                />
                <c-help-block />
                <ul class="list-data list-data-qualifications no-links no-hover has-button">
                  <li v-for="item in stateQualifications" :key="item.id" v-bind="{ item }">
                    {{ item.abbreviation }} - {{ item.name }}
                    <button type="button" @click="removeQualification(item)" class="btn btn-sm text-danger">
                      <i class="far fa-times"></i>
                    </button>
                  </li>
                </ul>
              </validation-provider>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <div class="btn-group">
              <c-button
                variant="warning"
                class="btn-md"
                @click="confirmBeforeTogglingArchived"
              >
                {{ course.archived ? "Restore Course" : "Archive Course" }}
              </c-button>
              <button
                type="button"
                class="
                  btn btn-md btn-warning
                  dropdown-toggle dropdown-toggle-split
                "
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item"
                    href=""
                    @click.prevent="confirmDeleteCourse"
                    >Delete Course</a
                  >
                </li>
              </ul>
            </div>
            <c-button
              class="ms-2 btn-md"
              variant="outline-primary"
              @click="confirmDuplicateCourse"
            >
              Duplicate
            </c-button>
          </div>
          <div class="col-auto" v-if="course.archived === false">
            <c-button
              validate-server
              class="btn-md"
              variant="outline-primary"
              v-if="course.provider.pending || course.published === false"
              @click="save('save')"
              key="saveDraft"
            >
              Save Draft
            </c-button>
            <template v-if="course.provider.pending === false">
              <c-button
                validate
                v-if="course.published === false"
                class="ms-2 btn-md"
                variant="primary"
                key="publish"
                @click="save('publish')"
              >
                Publish Course
              </c-button>
              <template v-else>
                <c-button
                  validate
                  key="unpublish"
                  class="ms-2 btn-md"
                  variant="outline-primary"
                  @click="save('unpublish')"
                >
                  Unpublish
                </c-button>
                <c-button
                  validate
                  key="saveChanges"
                  class="ms-2 btn-md"
                  @click="save('save')"
                >
                  Save Changes
                </c-button>
              </template>
            </template>
          </div>
        </div>
      </div>
    </validation-observer>

    <advanced-settings-card v-if="$isAn('admin')" />
  </fragment>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import { stateMapper } from '@/vuex/modules/courses'
import { toast } from '@chameleon/chameleon-vue'
import swal from 'sweetalert'
import http, { apiUrl } from '@/utils/http'
import SingleSelect from '@/components/forms/SingleSelect.vue'
import QuillTextarea from '@/components/forms/QuillTextarea'
import CourseFormTopics from '@/components/courses/CourseFormTopics.vue'
import CourseFormSpeakers from '@/components/courses/CourseFormSpeakers.vue'
import LocationsSelect from '@/components/locations/LocationsSelect.vue'
import ProfessionSelect from '@/components/professions/ProfessionSelect.vue'
import PrimaryFieldSelect from '@/components/primary-fields/PrimaryFieldSelect.vue'
import QualificationsSelect from '@/components/qualifications/QualificationsSelect.vue'
import AdvancedSettingsCard from '@/components/courses/admin/AdvancedSettingsCard.vue'
import GoogleMap from '@/components/maps/GoogleMap.vue'
import GoogleMapAutocompleteInput from '@/components/maps/GoogleMapAutocompleteInput.vue'
import GoogleMapMarker from '@/components/maps/GoogleMapMarker'

export default {
  mixins: [stateMapper],

  components: {
    GoogleMap,
    SingleSelect,
    QuillTextarea,
    LocationsSelect,
    ProfessionSelect,
    PrimaryFieldSelect,
    QualificationsSelect,
    GoogleMapAutocompleteInput,
    GoogleMapMarker,
    CourseFormTopics,
    CourseFormSpeakers,
    AdvancedSettingsCard,
  },

  watch: {
    stateProfessions: {
      handler (value) {
        const professionIds = value.map((profession) => profession.id)

        this.stateTopics = this.courseTopics
          .filter(({ id }) => {
            return this.stateTopics.map((topic) => topic.id).includes(id)
          })
          .filter((topic) => {
            if (topic.profession_id) {
              return professionIds.includes(topic.profession_id)
            }
            return professionIds.includes(this.courseTopics.find(({ id }) => id === topic.parent_id)?.profession_id)
          })

        this.statePrimaryFields = this.primaryFields
          .filter((primaryField) => {
            return this.statePrimaryFields
              .map((primaryField) => primaryField.id)
              .includes(primaryField.id)
          })
          .filter((primaryField) => {
            return professionIds.includes(primaryField.profession_id)
          })
      },
      immediate: false,
    },

    stateRestrictionProfessions: {
      handler (value) {
        const restrictionProfessionIds = value.map((profession) => profession.id)
        this.stateRestrictionPrimaryFields = this.primaryFields
          .filter((primaryField) => {
            return this.stateRestrictionPrimaryFields
              .map((primaryField) => primaryField.id)
              .includes(primaryField.id)
          })
          .filter((primaryField) => {
            return restrictionProfessionIds.includes(primaryField.profession_id)
          })

        this.stateQualifications = this.qualifications
          .filter((qualification) => {
            return this.stateQualifications
              .map((qualification) => qualification.id)
              .includes(qualification.id)
          })
          .filter((qualification) => {
            return restrictionProfessionIds.includes(qualification.profession_id)
          })
      },
      immediate: false,

    },
  },

  computed: {
    course: get('courses/course'),
    provider: get('providers/provider'),
    onlineCourse: get('courses/onlineCourse'),
    onDemandCourse: get('courses/onDemandCourse'),
    inPersonCourse: get('courses/inPersonCourse'),
    featuredImageUrl: sync('courses/course@featured_image_url'),
    professions: get('lists/professions/professions'),
    courseTopics: sync('courses/course@topics'),
    primaryFields: get('lists/primaryFields/primaryFields'),
    qualifications: get('lists/qualifications/qualifications'),

    shortDescriptionCharacters () {
      return this.course.short_description
        ? this.course.short_description.length
        : 0
    },

    mapCenter () {
      const { latitude, longitude } = this.course.address

      if (latitude && longitude) {
        return { lat: latitude, lng: longitude }
      }

      return { lat: -29.922, lng: 136.569 }
    },

    mapZoom () {
      const { latitude, longitude } = this.course.address

      if (latitude && longitude) {
        return 14
      }

      return 3
    },

    courseImageUploadUrl () {
      return apiUrl(`courses/${this.course.id}/image`)
    },

    selectedProfessions: {
      get () {
        return this.stateProfessions.map((profession) => profession.id)
      },
      set (value) {
        this.stateProfessions = this.professions.filter((profession) => value.includes(profession.id))
      },
    },

    selectedRestrictionProfessions: {
      get () {
        return this.stateRestrictionProfessions.map((profession) => profession.id)
      },
      set (value) {
        this.stateRestrictionProfessions = this.professions.filter((profession) => value.includes(profession.id))
      },
    },
  },

  data () {
    return {
      map: null,
      typeOptions: [
        { label: 'In Person', value: 'in_person' },
        { label: 'Online Course', value: 'online' },
        { label: 'Online On Demand', value: 'on_demand' },
      ],
      durationOptions: [
        { label: 'Minutes', value: 'minutes' },
        { label: 'Hours', value: 'hours' },
        { label: 'Days', value: 'days' },
        { label: 'Weeks', value: 'weeks' },
        { label: 'Months', value: 'months' },
        { label: 'Years', value: 'years' },
      ],
      copyLabel: 'Copy',
      currencies: [
        { label: 'AUD', value: 'AUD' },
        { label: 'USD', value: 'USD' },
        { label: 'EUR', value: 'EUR' },
        { label: 'GBP', value: 'GBP' },
        { label: 'JPY', value: 'JPY' },
        { label: 'CHF', value: 'CHF' },
        { label: 'AFN', value: 'AFN' },
        { label: 'ALL', value: 'ALL' },
        { label: 'DZD', value: 'DZD' },
        { label: 'AOA', value: 'AOA' },
        { label: 'ARS', value: 'ARS' },
        { label: 'AMD', value: 'AMD' },
        { label: 'AWG', value: 'AWG' },
        { label: 'ATS (EURO)', value: 'ATS' },
        { label: 'BEF', value: 'BEF' },
        { label: 'AZN', value: 'AZN' },
        { label: 'BSD', value: 'BSD' },
        { label: 'BDT', value: 'BDT' },
        { label: 'BBD', value: 'BBD' },
        { label: 'BYR', value: 'BYR' },
        { label: 'BZD', value: 'BZD' },
        { label: 'BMD', value: 'BMD' },
        { label: 'BTN', value: 'BTN' },
        { label: 'BOB', value: 'BOB' },
        { label: 'BAM', value: 'BAM' },
        { label: 'BWP', value: 'BWP' },
        { label: 'BRL', value: 'BRL' },
        { label: 'BND', value: 'BND' },
        { label: 'BGN', value: 'BGN' },
        { label: 'BIF', value: 'BIF' },
        { label: 'XOF', value: 'XOF' },
        { label: 'XPF', value: 'XPF' },
        { label: 'XAF', value: 'XAF' },
        { label: 'KHR', value: 'KHR' },
        { label: 'CAD', value: 'CAD' },
        { label: 'CVE', value: 'CVE' },
        { label: 'KYD', value: 'KYD' },
        { label: 'CLP', value: 'CLP' },
        { label: 'CNY', value: 'CNY' },
        { label: 'COP', value: 'COP' },
        { label: 'KMF', value: 'KMF' },
        { label: 'CDF', value: 'CDF' },
        { label: 'CRC', value: 'CRC' },
        { label: 'HRK', value: 'HRK' },
        { label: 'CUC', value: 'CUC' },
        { label: 'CUP', value: 'CUP' },
        { label: 'CYP', value: 'CYP' },
        { label: 'CZK', value: 'CZK' },
        { label: 'DKK', value: 'DKK' },
        { label: 'DJF', value: 'DJF' },
        { label: 'DOP', value: 'DOP' },
        { label: 'XCD', value: 'XCD' },
        { label: 'EGP', value: 'EGP' },
        { label: 'SVC', value: 'SVC' },
        { label: 'EEK', value: 'EEK' },
        { label: 'ETB', value: 'ETB' },
        { label: 'FKP', value: 'FKP' },
        { label: 'FIM', value: 'FIM' },
        { label: 'FJD', value: 'FJD' },
        { label: 'GMD', value: 'GMD' },
        { label: 'GEL', value: 'GEL' },
        { label: 'DMK', value: 'DMK' },
        { label: 'GHS', value: 'GHS' },
        { label: 'GIP', value: 'GIP' },
        { label: 'GTQ', value: 'GTQ' },
        { label: 'GNF', value: 'GNF' },
        { label: 'GYD', value: 'GYD' },
        { label: 'HTG', value: 'HTG' },
        { label: 'HNL', value: 'HNL' },
        { label: 'HKD', value: 'HKD' },
        { label: 'HUF', value: 'HUF' },
        { label: 'ISK', value: 'ISK' },
        { label: 'INR', value: 'INR' },
        { label: 'IDR', value: 'IDR' },
        { label: 'IRR', value: 'IRR' },
        { label: 'IQD', value: 'IQD' },
        { label: 'IED', value: 'IED' },
        { label: 'ILS', value: 'ILS' },
        { label: 'ITL', value: 'ITL' },
        { label: 'JMD', value: 'JMD' },
        { label: 'JOD', value: 'JOD' },
        { label: 'KZT', value: 'KZT' },
        { label: 'KES', value: 'KES' },
        { label: 'KWD', value: 'KWD' },
        { label: 'KGS', value: 'KGS' },
        { label: 'LAK', value: 'LAK' },
        { label: 'LVL', value: 'LVL' },
        { label: 'LBP', value: 'LBP' },
        { label: 'LSL', value: 'LSL' },
        { label: 'LRD', value: 'LRD' },
        { label: 'LYD', value: 'LYD' },
        { label: 'LTL', value: 'LTL' },
        { label: 'LUF', value: 'LUF' },
        { label: 'MOP', value: 'MOP' },
        { label: 'MKD', value: 'MKD' },
        { label: 'MGA', value: 'MGA' },
        { label: 'MWK', value: 'MWK' },
        { label: 'MYR', value: 'MYR' },
        { label: 'MVR', value: 'MVR' },
        { label: 'MTL', value: 'MTL' },
        { label: 'MRO', value: 'MRO' },
        { label: 'MUR', value: 'MUR' },
        { label: 'MXN', value: 'MXN' },
        { label: 'MDL', value: 'MDL' },
        { label: 'MNT', value: 'MNT' },
        { label: 'MAD', value: 'MAD' },
        { label: 'MZN', value: 'MZN' },
        { label: 'MMK', value: 'MMK' },
        { label: 'ANG', value: 'ANG' },
        { label: 'NAD', value: 'NAD' },
        { label: 'NPR', value: 'NPR' },
        { label: 'NLG', value: 'NLG' },
        { label: 'NZD', value: 'NZD' },
        { label: 'NIO', value: 'NIO' },
        { label: 'NGN', value: 'NGN' },
        { label: 'KPW', value: 'KPW' },
        { label: 'NOK', value: 'NOK' },
        { label: 'OMR', value: 'OMR' },
        { label: 'PKR', value: 'PKR' },
        { label: 'PAB', value: 'PAB' },
        { label: 'PGK', value: 'PGK' },
        { label: 'PYG', value: 'PYG' },
        { label: 'PEN', value: 'PEN' },
        { label: 'PHP', value: 'PHP' },
        { label: 'PLN', value: 'PLN' },
        { label: 'PTE', value: 'PTE' },
        { label: 'QAR', value: 'QAR' },
        { label: 'RON', value: 'RON' },
        { label: 'RUB', value: 'RUB' },
        { label: 'RWF', value: 'RWF' },
        { label: 'WST', value: 'WST' },
        { label: 'STD', value: 'STD' },
        { label: 'SAR', value: 'SAR' },
        { label: 'RSD', value: 'RSD' },
        { label: 'SCR', value: 'SCR' },
        { label: 'SLL', value: 'SLL' },
        { label: 'SGD', value: 'SGD' },
        { label: 'SKK', value: 'SKK' },
        { label: 'SIT', value: 'SIT' },
        { label: 'SBD', value: 'SBD' },
        { label: 'SOS', value: 'SOS' },
        { label: 'ZAR', value: 'ZAR' },
        { label: 'ESP', value: 'ESP' },
        { label: 'LKR', value: 'LKR' },
        { label: 'SHP', value: 'SHP' },
        { label: 'SDG', value: 'SDG' },
        { label: 'SRD', value: 'SRD' },
        { label: 'SZL', value: 'SZL' },
        { label: 'SEK', value: 'SEK' },
        { label: 'SYP', value: 'SYP' },
        { label: 'TWD', value: 'TWD' },
        { label: 'TZS', value: 'TZS' },
        { label: 'THB', value: 'THB' },
        { label: 'TOP', value: 'TOP' },
        { label: 'TTD', value: 'TTD' },
        { label: 'TND', value: 'TND' },
        { label: 'TRY', value: 'TRY' },
        { label: 'TMM', value: 'TMM' },
        { label: 'UGX', value: 'UGX' },
        { label: 'UAH', value: 'UAH' },
        { label: 'UYU', value: 'UYU' },
        { label: 'AED', value: 'AED' },
        { label: 'VUV', value: 'VUV' },
        { label: 'VEB', value: 'VEB' },
        { label: 'VND', value: 'VND' },
        { label: 'YER', value: 'YER' },
        { label: 'ZMK', value: 'ZMK' },
        { label: 'ZWD', value: 'ZWD' },
      ],
    }
  },

  methods: {
    updateCourse: call('courses/updateCourse'),
    deleteCourse: call('courses/deleteCourse'),
    archiveCourse: call('courses/archiveCourse'),
    restoreCourse: call('courses/restoreCourse'),

    updateAddress ({ formattedAddress, latitude, longitude, components }) {
      this.stateAddressFormattedAddress = formattedAddress
      this.stateAddressLatitude = latitude
      this.stateAddressLongitude = longitude
      this.stateAddressStreet =
          components.long.street_number && components.long.route
            ? `${components.long.street_number} ${components.long.route}`
            : null
      this.stateAddressSuburb = components.long.locality
      this.stateAddressPostcode = components.long.postal_code ?? null
      this.stateAddressState = components.long.administrative_area_level_1
      this.stateAddressStateShort =
          components.short.administrative_area_level_1
      this.stateAddressCountry = components.long.country
    },

    async deleteFeaturedImage () {
      await http.delete(`courses/${this.course.id}/image`)

      this.featuredImageUrl = null
    },

    featuredImageUploaded ({ url }) {
      this.featuredImageUrl = url
    },

    async save (action) {
      if (action === 'duplicate') {
        try {
          return await http.get(`/courses/${this.course.id}/duplicate`)
        } catch (error) {
          if (error.response?.status === 400 && error.response.data.message === 'mismatch_provider_id') {
            toast('You do not have permission to create a course for that provider.', 'danger')
          } else {
            throw error
          }
        }
      }
      try {
        await this.updateCourse({
          id: this.course.id,
          payload: {
            ...this.course,
            action,
          },
        })

        let message

        if (action === 'publish') {
          if (this.course.status === 'pending') {
            message =
                'Course has been published and is pending review from Med CPD.'
          } else {
            message = 'Course has been published.'
          }
        } else if (action === 'unpublish') {
          message = 'Course is no longer published.'
        } else {
          message = 'Course has been saved.'
        }

        toast(message, 'success')
      } catch (error) {
        if (error.response && error.response.status === 422) {
          throw error
        } else {
          toast('There was a problem saving the course.', 'danger')
        }
      }
    },

    async confirmDuplicateCourse () {
      if (
        !(await swal({
          title: 'Duplicate Course',
          text: 'Are you sure you want to duplicate this course?',
          buttons: {
            cancel: { text: 'No', value: false, visible: true },
            confirm: {
              text: 'Yes, duplicate course',
              value: true,
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: false,
          closeOnClickOutside: false,
        }))
      ) {
        return
      }

      const response = await this.save('duplicate')

      swal.close()

      toast('Course has been duplicated.', 'info')

      this.$router.push({ name: 'courses.show', params: { courseId: response.data.data.id } })
    },

    async confirmDeleteCourse () {
      if (
        !(await swal({
          title: 'Delete Course',
          text: 'Are you sure you want to delete this course?',
          buttons: {
            cancel: { text: 'No', value: false, visible: true },
            confirm: {
              text: 'Yes, delete course',
              value: true,
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: true,
          closeOnClickOutside: false,
        }))
      ) {
        return
      }

      await this.deleteCourse(this.course.id)

      swal.close()

      toast('Course has been deleted.', 'info')

      this.$router.replace({ name: 'courses' })
    },

    async confirmBeforeTogglingArchived () {
      const title = this.course.archived ? 'Restore Course' : 'Archive Course'
      const text = this.course.archived
        ? 'Are you sure you want to restore this course?'
        : 'Are you sure you want to archive this course?'

      if (
        !(await swal({
          title,
          text,
          buttons: {
            cancel: { text: 'No', value: false, visible: true },
            confirm: {
              text: 'Yes',
              value: true,
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: true,
          closeOnClickOutside: false,
        }))
      ) {
        return
      }

      if (this.course.archived) {
        await this.restoreCourse(this.course.id)

        toast('Course has been restored.', 'info')
      } else {
        await this.archiveCourse(this.course.id)

        toast('Course has been archived.', 'info')
      }

      swal.close()
    },

    setFree () {
      this.statePrice = 0
    },

    copy () {
      this.$refs.questionnaireUrl.focus()
      document.execCommand('copy')
      this.copyLabel = 'Copied!'
    },

    removeLocation (item) {
      const locations = [...this.course.locations]
      const index = locations.findIndex((location) => location.id === item.id)
      locations.splice(index, 1)
      this.stateLocations = locations
    },

    removeProfession (item) {
      const professions = [...this.course.professions]
      const index = professions.findIndex((profession) => profession.id === item.id)
      professions.splice(index, 1)
      this.stateProfessions = professions
    },

    removeRestrictionProfession (item) {
      const professions = [...this.course.restriction_professions]
      const index = professions.findIndex((profession) => profession.id === item.id)
      professions.splice(index, 1)
      this.stateRestrictionProfessions = professions
    },

    removePrimaryField (item) {
      const primaryFields = [...this.course.primary_fields]
      const index = primaryFields.findIndex((primaryField) => primaryField.id === item.id)
      primaryFields.splice(index, 1)
      this.statePrimaryFields = primaryFields
    },

    removeQualification (item) {
      const qualifications = [...this.course.qualifications]
      const index = qualifications.findIndex((qualification) => qualification.id === item.id)
      qualifications.splice(index, 1)
      this.stateQualifications = qualifications
    }
  },
}
</script>
