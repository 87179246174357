<template>
  <flatpickr
    :value="dateRange"
    name="date_range"
    :config="dateRangeConfig"
    @on-change="dateRangeChanged"
    @on-close="dateRangeClosed"
    class="form-control date-range"
  />
</template>

<script>
import dayjs from 'dayjs'
import Flatpickr from 'vue-flatpickr-component'

export default {
  components: {
    Flatpickr
  },

  props: {
    value: Object,
    utc: Boolean
  },

  data () {
    return {
      startDate: null,
      endDate: null,
      dateRangeConfig: {
        altInput: true,
        altFormat: 'd/m/Y',
        mode: 'range',
        showMonths: 2
      }
    }
  },

  computed: {
    dateRange: {
      get () {
        if (this.startDate === null || this.endDate === null) {
          return null
        }

        return [this.startDate, this.endDate]
      },

      set (value) {
        //
      }
    }
  },

  created () {
    if (this.value) {
      const { startDate, endDate } = this.value

      this.startDate = startDate
      this.endDate = endDate
    }
  },

  methods: {
    dateRangeClosed (dates) {
      if (dates.length !== 2) {
        this.$emit('input', null)
      }
    },

    dateRangeChanged (dates) {
      let startDate
      let endDate

      if (dates.length === 2) {
        [startDate, endDate] = dates.map(date => dayjs(date))

        // If getting dates in UTC we'll make sure dates are at the start/end of the day before converting to
        // UTC then getting the ISO 8601 string.
        if (this.utc) {
          startDate = startDate.startOf('day').utc().toISOString()
          endDate = endDate.endOf('day').utc().toISOString()
        } else {
          startDate = startDate.format('YYYY-MM-DD')
          endDate = endDate.format('YYYY-MM-DD')
        }
      } else if (dates.length === 0) {
        startDate = null
        endDate = null
      } else {
        return
      }

      if (startDate === this.startDate && endDate === this.endDate) {
        return
      }

      this.startDate = startDate
      this.endDate = endDate

      this.$emit('input', startDate && endDate ? { startDate, endDate } : null)
    }
  }
}
</script>
