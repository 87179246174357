<template>
  <multiselect
    class="multiselect-sort-by-horizontal"
    placeholder="Select"
    :options="options"
    label="label"
    v-model="sortSelected"
    :show-labels="false"
  />
</template>

<script>
export default {
  props: {
    value: Object
  },

  data () {
    return {
      options: [
        { label: 'Recommended', value: { sort: 'plqs', order: 'desc' } },
        { label: 'Course LQS', value: { sort: 'clqs', order: 'desc' } },
        { label: 'Likes', value: { sort: 'like', order: 'desc' } },
        { label: 'Newest First', value: { sort: 'date', order: 'desc' } },
        { label: 'Oldest First', value: { sort: 'date', order: 'asc' } },
        { label: 'Alphabetical A-Z', value: { sort: 'name', order: 'asc' } },
        { label: 'Alphabetical Z-A', value: { sort: 'name', order: 'desc' } },
      ]
    }
  },

  computed: {
    sortSelected: {
      get () {
        return this.options.find(({ value }) => value.sort === this.value.sort && value.order === this.value.order)
      },

      set ({ value }) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
