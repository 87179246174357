<template>
  <fragment>
    <app-header title="Browse By Topic" />

    <div class="layout-sidesheet row">
      <div class="col main-content">
        <vue-scroll>
          <div class="container">
            <div class="row">
              <div class="col-lg d-flex align-items-center">
                <h3 class="selected-topic" v-if="topicName">
                  {{ topicName }}
                </h3>
              </div>
              <div class="col-lg-auto">
                <div class="row">
                  <label class="col-auto col-form-label">Sort By</label>
                  <div class="col">
                    <sort-select v-model="sortSelected" />
                  </div>
                </div>
              </div>
              <div class="col-lg-auto">
                <div class="row">
                  <div class="form-check form-switch">
                    <input type="checkbox" v-model="filters.past" id="showPastEvents" class="form-check-input" />
                    <label for="showPastEvents" class="form-check-label">Show Past Events</label>
                  </div>
                </div>
              </div>
            </div>

            <c-data-list
              :items="courses"
              @load="load"
              class="results-list"
              target-vue-scroll
              remove-default-class
            >
              <template #default="{ item: course }">
                <course-card v-bind="{ course }">
                  <template #bookmark>
                    <bookmark-button
                      v-bind="{ course }"
                      v-c-modal:addLearningPlan="{ course }"
                    />
                  </template>
                </course-card>
              </template>
            </c-data-list>
          </div>
          <slot name="footer"/>
        </vue-scroll>
      </div>
      <topics-sidesheet class="col-xl-4 col-xxl-3" v-model="filters" />
    </div>

    <add-learning-plan-modal
      ref="addLearningPlan"
      v-bind="{ courses }"
      @updated="setCourses"
    />

    <portal to="headerRight">
      <ul class="header-nav justify-content-end">
        <li>
          <c-button type="button" class="btn btn-sidesheet-show search navbar-user-link d-xl-none" @click="toggleSidebar">
            <i class="fal fa-search"></i> Search
          </c-button>
        </li>
      </ul>
    </portal>
  </fragment>
</template>

<script>
import { useQueryParamFilters, compareQueryParams } from '@codium/codium-vue'
import { toNumber } from 'lodash'
import { get, call } from 'vuex-pathify'
import TopicsSidesheet from '@/components/courses/TopicsSidesheet.vue'
import CourseCard from '@/components/courses/user/CourseCard.vue'
import BookmarkButton from '@/components/learning-plans/BookmarkButton.vue'
import AddLearningPlanModal from '@/components/courses/AddLearningPlanModal.vue'
import SortSelect from '@/components/courses/SortSelect.vue'

export default {
  mixins: [
    useQueryParamFilters({
      formatters: {
        topics: value => toNumber(value),
        professions: value => Array.isArray(value) ? value.map(toNumber) : [toNumber(value)],
      }
    })
  ],

  components: {
    TopicsSidesheet,
    CourseCard,
    BookmarkButton,
    AddLearningPlanModal,
    SortSelect
  },

  data () {
    return {
      filters: {
        professions: null,
        topics: null,
        sort: 'date',
        order: 'desc',
        past: false
      }
    }
  },

  created () {
    if (!this.$route.query.professions?.length && this.$user.profession_id) {
      this.filters.professions = [this.$user.profession_id]
    }
  },

  computed: {
    topics: get('courses/topics/flattened'),
    courses: get('courses/published/courses'),

    sortSelected: {
      get () {
        return { sort: this.filters.sort, order: this.filters.order }
      },

      set ({ sort, order }) {
        this.filters.sort = sort
        this.filters.order = order
      }
    },

    topicName () {
      if (this.filters.topics === null) {
        return
      }

      const topic = this.topics.find(({ id }) => id === this.filters.topics)

      return topic?.name
    }
  },

  watch: {
    '$route.query' (query) {
      if (Object.keys(query).length === 0) {
        this.filters = this.$options.data().filters
      }
    },

    queryParams: compareQueryParams(async function (params) {
      await this.getCourses({ params: { ...params, professions: params.professions }, fresh: true })
    })
  },

  beforeDestroy () {
    this.resetCourses()
  },

  methods: {
    getCourses: call('courses/published/getCourses'),
    setCourses: call('courses/published/setCourses'),
    resetCourses: call('courses/published/resetCourses'),

    async load (done) {
      try {
        await this.getCourses({ params: this.queryParams })

        done()
      } catch {
        done(true)
      }
    },

    toggleSidebar () {
      document.querySelector('.side-sheet').classList.add('show')
    }
  }
}
</script>
