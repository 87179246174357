<template>
  <li class="accreditation-values-list-row">
    <validation-observer tag="div" class="row">
      <div class="col-xl-course-details mb-3 mb-xl-0">
        <img
          v-if="accreditationValue.body.logo_url"
          :src="accreditationValue.body.logo_url"
          width="150"
          height="150"
          alt=""
          class="thumbnail"
        />
        <p class="strong text-truncate">
          {{accreditationValue.body.body_acronym}} - {{ accreditationValue.body.name }}
        </p>
      </div>
      <div class="col-6 col-md-4 col-xl-course-details">
        <validation-provider
            tag="div"
            name="value"
            :vid="`${index}.educational_activity`"
            :rules="{ required: (measuringOutcomes !== null && measuringOutcomes !== '') ||  (reviewingPerformance!== null && reviewingPerformance !== ''), decimal: true }"
            :custom-messages="{ required: 'Required', decimal: 'Numeric or decimal' }"
            v-slot="{ failed }"
          >
          <span class="data-label d-xl-none">Educational Activity</span>
          <div class="input-group input-group-sm" :class="{ 'is-invalid': failed }">
            <c-input type="text" class="form-control" v-model="educationalActivity"/>
            <span class="input-group-text">hrs</span>
          </div>
          <c-help-block/>
        </validation-provider>
      </div>

      <div class="col-6 col-md-4 col-xl-course-details">
        <validation-provider
            tag="div"
            name="value"
            :vid="`${index}.reviewing_performance`"
            :rules="{ required: (measuringOutcomes !== null && measuringOutcomes !== '') ||  (educationalActivity!== null && educationalActivity!== ''), decimal: true }"
            :custom-messages="{ required: 'Required', decimal: 'Numeric or decimal'}"
            v-slot="{ failed }"
          >
          <span class="data-label d-xl-none">Reviewing Performance</span>
          <div class="input-group input-group-sm" :class="{ 'is-invalid': failed }">
            <c-input type="text" class="form-control" v-model="reviewingPerformance"/>
            <span class="input-group-text">hrs</span>
          </div>
          <c-help-block/>
        </validation-provider>
      </div>

      <div class="col-6 col-md-4 col-xl-course-details">
        <validation-provider
            tag="div"
            name="value"
            :vid="`${index}.measuring_outcomes`"
            :rules="{ required: (reviewingPerformance !== null && reviewingPerformance !== '') ||  (educationalActivity!== null && educationalActivity!== ''), decimal: true }"
            :custom-messages="{ required: 'Required', decimal: 'Numeric or decimal' }"
            v-slot="{ failed }"
          >
          <span class="data-label d-xl-none">Measuring Outcomes</span>
          <div class="input-group input-group-sm" :class="{ 'is-invalid': failed }">
            <c-input class="form-control" v-model="measuringOutcomes"/>
            <span class="input-group-text">hrs</span>
          </div>
          <c-help-block/>
        </validation-provider>
      </div>

      <div class="col-6 col-md-4 col-xl-course-details">
        <validation-provider
            tag="div"
            name="value"
            :vid="`${index}.points`"
            :rules="{ decimal: true }"
            :custom-messages="{ decimal: 'Numeric or decimal' }"
            v-slot="{ failed }"
          >
          <span class="data-label d-xl-none">Points</span>
          <div class="input-group input-group-sm" :class="{ 'is-invalid': failed }">
            <c-input class="form-control" v-model="points"/>
            <span class="input-group-text">pts</span>
          </div>
          <c-help-block/>
        </validation-provider>
      </div>

      <div class="col-6 col-md-4 col-xl-course-details">
        <validation-provider
            tag="div"
            name="value"
            :vid="`${index}.hours`"
            :rules="{ decimal: true }"
            :custom-messages="{ decimal: 'Numeric or decimal' }"
            v-slot="{ failed }"
          >
          <span class="data-label d-xl-none">Hours</span>
          <div class="input-group input-group-sm" :class="{ 'is-invalid': failed }">
            <c-input class="form-control" v-model="hours"/>
            <span class="input-group-text">hrs</span>
          </div>
          <c-help-block/>
        </validation-provider>
      </div>

    </validation-observer>
  </li>
</template>

<script>
export default {
  props: {
    accreditationValue: Object,
    index: Number
  },

  computed: {
    educationalActivity: {
      get () {
        return this.accreditationValue.educationalActivity
      },

      set (educationalActivity) {
        this.$emit('update', { ...this.accreditationValue, educationalActivity })
      }
    },

    reviewingPerformance: {
      get () {
        return this.accreditationValue.reviewingPerformance
      },

      set (reviewingPerformance) {
        this.$emit('update', { ...this.accreditationValue, reviewingPerformance })
      }
    },

    measuringOutcomes: {
      get () {
        return this.accreditationValue.measuringOutcomes
      },

      set (measuringOutcomes) {
        this.$emit('update', { ...this.accreditationValue, measuringOutcomes })
      }
    },

    points: {
      get () {
        return this.accreditationValue.points
      },

      set (points) {
        this.$emit('update', { ...this.accreditationValue, points })
      }
    },

    hours: {
      get () {
        return this.accreditationValue.hours
      },

      set (hours) {
        this.$emit('update', { ...this.accreditationValue, hours })
      }
    },
  },

  methods: {
    error (errors) {
      for (const key in errors) {
        if (errors[key].length > 0) {
          return errors[key][0]
        }
      }
    }
  }
}
</script>

<style>
.form-control.is-invalid {
  border-color: #C62B3B !important;
  border-right: 1px solid;
}
</style>
