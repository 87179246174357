<template>
  <multiselect
    as="multiselect"
    class="multiselect-multiple"
    v-model="selected"
    :placeholder="placeholder"
    :show-labels="false"
    :options="options"
    :label="label"
    :track-by="trackBy"
    multiple
    :close-on-select="false"
    :class="[invalidClass]"
    :disabled="checkBoxChecked || disabled"
  >
    <template #tag>
      <span></span>
    </template>
  </multiselect>

</template>

<script>
import { useIsInvalid } from '@chameleon/chameleon-vue'
import { get, call } from 'vuex-pathify'

export default {
  mixins: [useIsInvalid],

  props: {
    value: {
      type: Array
    },
    checkBoxChecked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    professionId: {
      type: Number,
      default: null
    },
    professions: {
      type: Array,
      default: null
    },
  },

  data () {
    return {
      label: 'name',
      trackBy: 'id',
      placeholder: 'Add Primary Fields',
      // We need to store options locally due to being used twice in the parent component
      // CoursesShowDetails
      options: [],
    }
  },

  async created () {
    if (this.primaryFields.length === 0) {
      await this.getPrimaryFields({})
    }

    if (this.professions) {
      this.options = this.primaryFields
        .filter((primaryField) => {
          return this.professions
            .map(({ id }) => id)
            .includes(primaryField.profession_id)
        })
    }

    if (this.professionId) {
      this.options = this.primaryFields
        .filter((primaryField) => primaryField.profession_id === this.professionId)
    }
  },

  watch: {
    professionId: {
      handler (val) {
        if (!val) {
          return
        }

        this.options = this.primaryFields
          .filter((primaryField) => primaryField.profession_id === val)
      },
      immediate: true
    },
    professions: {
      handler (val) {
        if (!val) {
          return
        }

        this.options = this.primaryFields
          .filter((primaryField) => {
            return val
              .map(({ id }) => id)
              .includes(primaryField.profession_id)
          })
      },
      immediate: true
    }
  },

  computed: {
    ...get('lists/primaryFields', ['primaryFields']),

    selected: {
      get () {
        const ids = this.value.map(({ id }) => id)

        return this.primaryFields.filter(({ id }) => {
          return ids.includes(id)
        })
      },

      set (values) {
        this.$emit('input', values)
      }
    }
  },

  methods: {
    ...call('lists/primaryFields', ['getPrimaryFields']),
  },
}
</script>

<style>
  .list-data {
    margin-bottom: 0;
  }
</style>
