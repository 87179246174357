<template>
  <li>
    <div class="row">
      <div class="col overflow-hidden">
        <div class="row gx-0">
          <div class="col-auto">
            <img
              :src="logoUrl"
              width="150"
              height="150"
              alt=""
              class="thumbnail"
            />
          </div>
          <div class="col d-flex align-items-center">
            <strong>{{ accreditationValue.body.name }}</strong>
          </div>
        </div>
      </div>
      <div class="col-xl-auto d-flex align-items-center">
        <template v-if="hasSpecificValues">
          <span class="tag tag-cpd-value">
            <span class="type" data-bs-toggle="tooltip" title="Educational Activity">EA</span>
            {{ accreditationValue.educationalActivity | DisplayZeroIfNull }}
            hrs
          </span>
          <span class="tag tag-cpd-value">
            <span class="type" data-bs-toggle="tooltip" title="Reviewing Performance">RP</span>
            {{ accreditationValue.reviewingPerformance | DisplayZeroIfNull }}
            hrs
          </span>
          <span class="tag tag-cpd-value">
            <span class="type" data-bs-toggle="tooltip" title="Measuring Outcomes">MO</span>
            {{ accreditationValue.measuringOutcomes | DisplayZeroIfNull }}
            hrs
          </span>
        </template>

        <span v-if="accreditationValue.points" class="tag tag-cpd-value px-0">
          {{ accreditationValue.points }} Point{{ accreditationValue.points > 1 ? 's' : '' }}
        </span>

        <span v-if="accreditationValue.hours" class="tag tag-cpd-value px-0">
          {{ accreditationValue.hours }} Hour{{ accreditationValue.hours > 1 ? 's' : '' }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { placeholders } from '@/utils/placeholders'

export default {
  props: {
    accreditationValue: Object
  },

  computed: {
    logoUrl () {
      return this.accreditationValue.body.logo?.url ?? placeholders.square
    },

    unit () {
      switch (this.accreditationValue.unit) {
        case 'hour':
          return 'hours'
        case 'point':
          return 'points'
      }

      return null
    },

    hasSpecificValues () {
      return this.accreditationValue.educationalActivity !== null ||
        this.accreditationValue.reviewingPerformance !== null ||
        this.accreditationValue.measuringOutcomes !== null
    }
  }
}
</script>
