var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"form-label"},[_vm._v("Topics")]),_c('div',{staticClass:"card"},[_c('ul',{staticClass:"list-data list-data-course-topics no-links no-hover has-button"},[_vm._l((_vm.topicsGrouped),function(topic){return [_c('li',{key:("topic-" + (topic.id))},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col flex-column align-items-start justify-content-center"},[_c('span',[_vm._v(_vm._s(topic.name))]),_c('validation-provider',{attrs:{"slim":"","vid":topic.idKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [(failed)?_c('span',{staticClass:"invalid-feedback d-block fw-normal"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","vid":topic.featuredKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [(failed)?_c('span',{staticClass:"invalid-feedback d-block fw-normal"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"form-check form-check-star me-2"},[_c('input',{staticClass:"form-check-input",attrs:{"id":("topic-check-" + (topic.id)),"type":"radio"},domProps:{"checked":topic.featured},on:{"click":function($event){return _vm.updateFeaturedTopic(topic)}}}),_c('label',{attrs:{"for":("topic-check-" + (topic.id))}})])])]),_c('button',{staticClass:"btn btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeTopic(topic)}}},[_c('i',{staticClass:"far fa-trash-alt"})])]),_vm._l((topic.children),function(child){return _c('li',{key:("topic-" + (child.id)),staticClass:"sub"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"\n                col\n                d-flex\n                flex-column\n                align-items-start\n                justify-content-center\n              "},[_vm._v(" "+_vm._s(child.name)+" "),_c('validation-provider',{attrs:{"slim":"","vid":child.idKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [(failed)?_c('span',{staticClass:"invalid-feedback d-block fw-normal"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","vid":child.featuredKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [(failed)?_c('span',{staticClass:"invalid-feedback d-block fw-normal"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"form-check form-check-star me-2"},[_c('input',{staticClass:"form-check-input",attrs:{"id":("topic-check-" + (child.id)),"type":"radio"},domProps:{"checked":child.featured},on:{"click":function($event){return _vm.updateFeaturedTopic(child)}}}),_c('label',{attrs:{"for":("topic-check-" + (child.id))}})])])]),_c('button',{staticClass:"btn btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeTopic(child)}}},[_c('i',{staticClass:"far fa-trash-alt"})])])})]})],2),_c('validation-provider',{staticClass:"card-body pt-0 p-2 d-flex align-items-center",attrs:{"tag":"div","name":"topics","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
return [_c('input',{class:{ 'is-invalid': failed },attrs:{"type":"hidden"},domProps:{"value":_vm.topicsSelected.join(',')}}),_c('c-help-block',{staticClass:"w-auto"}),_c('button',{directives:[{name:"c-modal",rawName:"v-c-modal:topics",arg:"topics"}],staticClass:"ms-auto btn btn-sm btn-primary",attrs:{"type":"button","disabled":!_vm.professionIds.length}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" Topic ")])]}}])})],1),_c('select-topics-drawer',{ref:"topics",attrs:{"professions":_vm.professionIds},model:{value:(_vm.topicsSelected),callback:function ($$v) {_vm.topicsSelected=$$v},expression:"topicsSelected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }