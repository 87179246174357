<template>
  <fragment>
    <div class="alert bg-primary mb-4">
      <div class="alert-content">
        <p>This course is pending review from Med CPD before being published.</p>
      </div>
      <div class="alert-actions">
        <c-button
          type="button"
          class="btn"
          variant=""
          @click="reject"
        >
          Reject
        </c-button>
        <c-button type="button" class="btn" variant="" @click="approve">Approve</c-button>
      </div>
    </div>
  </fragment>
</template>

<script>
import { call, get, sync } from 'vuex-pathify'
import { toast } from '@chameleon/chameleon-vue'

export default {
  computed: {
    course: get('courses/course'),
    review: sync('courses/course@review'),
    rejectionReason: sync('courses/course@rejection_reason'),
    courseSettings: get('courses/courseSettings'),
  },

  methods: {
    updateCourse: call('courses/updateCourse'),
    updateCourseSettings: call('courses/updateCourseSettings'),
    openCourseRejectionModal: call('courses/openCourseRejectionModal'),

    async save () {
      await this.updateCourse({
        id: this.course.id,
        payload: {
          ...this.course,
          action: 'save',
        }
      })
    },

    async reject () {
      try {
        this.rejectionReason = await this.openCourseRejectionModal()

        await this.updateCourseSettings({
          id: this.course.id,
          payload: {
            ...this.courseSettings,
            review: 'rejected',
          }
        })

        toast('Course was rejected successfully.', 'success')
      } catch {
        //
      }
    },

    async approve () {
      await this.save()

      await this.updateCourseSettings({
        id: this.course.id,
        payload: {
          ...this.courseSettings,
          review: 'approved'
        }
      })

      toast('Course was approved successfully.', 'success')
    }
  }
}
</script>
