<template>
  <span class="status" :class="[statusClass]">{{ statusFormatted }}</span>
</template>

<script>
export default {
  props: {
    status: String,
    review: String
  },

  computed: {
    statusFormatted () {
      switch (this.status) {
        case 'draft':
          return 'Draft'
        case 'pending':
          return 'Pending Review'
        case 'published':
          return 'Published'
        case 'rejected':
          return 'Rejected'
        case 'archived':
          return 'Archived'
      }

      throw new Error('Unknown status for course.')
    },

    statusClass () {
      switch (this.status) {
        case 'draft':
        case 'pending':
          return 'status-info'
        case 'published':
          return 'status-success'
        case 'rejected':
        case 'archived':
          return 'status-danger'
      }

      throw new Error('Unknown status for course.')
    }
  }
}
</script>
