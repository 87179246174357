<template>
  <div class="side-sheet side-sheet-filters overflow-auto">
    <div class="sidesheet-inner overflow-auto">
      <div class="card-body pb-0 sticky-top bg-white">
        <div class="row">
          <div class="col d-flex align-items-center">
            <h2 class="m-0">Topics</h2>
          </div>
          <div class="col-auto">
            <div class="form-check form-switch pe-3 pe-xl-0">
              <input
                type="checkbox"
                id="auto-collapse"
                class="form-check-input"
                v-model="autocollapse"
              />
              <label for="auto-collapse" class="form-check-label small">
                Auto Collapse
              </label>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-sidesheet-show" @click="toggleSidebar">
          <i class="far fa-times"></i>
        </button>
      </div>

      <div class="mt-3 p-2">
        <label class="form-label">Professions</label>
        <ProfessionSelect v-model="selectedProfessions" multiple />
      </div>

      <vue-scroll class="mt-3">
        <ul class="list-data no-hover list-data-topic accordion accordion-flush mt-0" id="accordion-topic">
          <li class="accordion-item" v-for="topic in topics" :key="topic.id">
            <div :id="`accordion-topic-heading-${topic.id}`">
              <button
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#accordion-topic-item-${topic.id}`"
                aria-expanded="false"
                :aria-controls="`accordion-topic-item-${topic.id}`"
                class="accordion-button collapsed"
              >
                {{ topic.name }}
              </button>
            </div>
            <ul
              :id="`accordion-topic-item-${topic.id}`"
              :aria-labelledby="`accordion-topic-heading-${topic.id}`"
              class="accordion-collapse collapse"
              :data-topic="topic.id"
              :class="{ show: initiallyOpen === topic.id || topic.children.map(({ id }) => id).includes(initiallyOpen) }"
            >
              <li :class="{ active: value.topics === topic.id }">
                <a href="" @click.prevent="setTopics(topic.id)">
                  <div class="row">
                    <div class="col text-truncate">
                      <p class="text-truncate">View All</p>
                    </div>
                    <div class="col-auto">
                      <span class="course-count">{{ topic.courses_count }} {{ topic.courses_count === 1 ? 'Course' : 'Courses' }}</span>
                    </div>
                  </div>
                </a>
              </li>
              <li v-for="child in topic.children" :key="child.id" :class="{ active: value.topics === child.id }">
                <a href="" @click.prevent="setTopics(child.id)">
                  <div class="row">
                    <div class="col text-truncate">
                      <p class="text-truncate">{{ child.name }}</p>
                    </div>
                    <div class="col-auto">
                      <span class="course-count">
                        {{ child.courses_count }} {{ child.courses_count === 1 ? 'Course' : 'Courses' }}
                      </span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { Collapse } from 'bootstrap'
import ProfessionSelect from '@/components/professions/ProfessionSelect.vue'

export default {
  props: {
    value: Object,
  },

  components: {
    ProfessionSelect
  },

  data () {
    return {
      autocollapse: true,
      initiallyOpen: null,
      accordions: []
    }
  },

  computed: {
    topics: get('courses/topics/topics'),

    selectedProfessions: {
      get () {
        return this.value.professions
      },

      set (value) {
        this.$emit('input', {
          ...this.value,
          professions: value,
        })
      }
    },
  },

  async created () {
    this.initiallyOpen = this.value.topics

    await this.getTopics({ params: { professions: this.value.professions } })

    this.$nextTick(this.bindCollapses)
  },

  watch: {
    async autocollapse () {
      for (const accordion of this.accordions) {
        accordion.dispose()
      }

      this.bindCollapses()
    },

    'value.professions': {
      async handler (newValue, oldValue) {
        if (newValue === oldValue) {
          return
        }

        await this.getTopics({ params: { professions: this.value.professions } })
      },
      deep: true
    }
  },

  methods: {
    getTopics: call('courses/topics/getTopics'),

    bindCollapses () {
      const elements = document.querySelectorAll('.accordion-collapse')

      for (const element of elements) {
        const accordion = new Collapse(element, {
          toggle: false,
          parent: this.autocollapse ? '#accordion-topic' : ''
        })

        this.accordions.push(accordion)

        if (this.autocollapse) {
          accordion.hide()
        }
      }
    },

    toggleSidebar () {
      document.querySelector('.side-sheet').classList.remove('show')
    },

    setTopics (topics) {
      this.$emit('input', {
        ...this.value,
        topics,
      })
    }
  }
}
</script>
