<template>
  <validation-observer tag="div" class="modal" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Reject Course</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <validation-provider tag="div" class="modal-body" name="reason" rules="required|max:50000">
          <p>Please provide a reason why this course is not being approved.</p>

          <label class="visually-hidden">Reason</label>

          <c-textarea rows="5" v-model="reason"/>

          <c-help-block/>
        </validation-provider>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <c-button validate @click="send">
            Send
          </c-button>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { CModal } from '@chameleon/chameleon-vue'
import { sync } from 'vuex-pathify'

export default {
  extends: CModal,

  name: 'RejectReasonModal',

  data () {
    return {
      reason: null
    }
  },

  computed: {
    courseRejectionPromise: sync('courses/courseRejectionPromise'),
  },

  watch: {
    courseRejectionPromise (promise) {
      if (promise && promise.resolve && promise.reject) {
        this.open()
      }
    }
  },

  mounted () {
    this.addEventListener('close', () => {
      if (this.courseRejectionPromise) {
        this.courseRejectionPromise.reject(new Error('closed'))
      }

      this.courseRejectionPromise = null
    })
  },

  methods: {
    async send () {
      this.courseRejectionPromise.resolve(this.reason)

      this.close()
    }
  }
}
</script>
