<template>
  <fragment>
    <app-header v-bind="{ title }" />

    <course-not-found v-if="courseNotFound"/>

    <div class="container" v-else-if="course.id">
      <div class="col-auto course-top-bar mb-2">
        <div class="row">
          <div class="back-button col-lg-10">
            <a href="" class="text-primary" @click.prevent="$router.go(-1)">
              <i class="far fa-chevron-left me-2"></i> Back
            </a>
          </div>

          <div class="like-button col-lg-2">
            <button
              class="btn"
              :class="{ active: course.liked }"
              title="Likes"
              v-c-tooltip.hover
              @click="likeOrUnlike"
              >
                <i class="fad fa-thumbs-up fa-fw"></i> {{ course.likes_count }}
            </button>
          </div>
        </div>
      </div>

      <article>
        <div class="card card-course-full">
          <img
            class="featured"
            :src="featuredImageUrl"
            width="1280"
            height="720"
          />

          <div class="row">
            <section class="col-xl-8">
              <div class="card-body card-body-lg pe-xl-0">
                <div class="row mb-3">
                  <div class="col-auto">
                    <h1 class="h2 m-0">{{ course.name }}</h1>
                  </div>
                  <div
                    class="col d-flex align-items-center"
                    v-if="course.previous"
                  >
                    <span class="text-primary"
                      ><i class="far fa-history"></i> Previous course</span
                    >
                  </div>
                </div>

                <footer>
                  <router-link :to="{ name: 'courses', params: { providerId: course.provider.id } }">
                    {{ course.provider.name }}
                    <!-- <img v-if="course.provider_square_logo_url" :src="course.provider_square_logo_url" width="30" height="30" alt="" class="provider_img"> -->
                    <!-- <img v-else :src="placeholders.square" width="30" height="30" alt="" class="provider_img"> -->
                  </router-link>
                </footer>

                <learner-quality-score :course="course"></learner-quality-score>

                <div class="share-container">
                  <div class="row gx-2">
                    <div class="col d-flex align-items-center">
                      <h3>Share This Course</h3>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                      <ul class="share-links">
                        <li>
                          <a :href="facebookShareUrl" target="_blank" @click="logShareActivity('facebook')" >
                            <i class="fab fa-fw fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a :href="twitterShareUrl" target="_blank" @click="logShareActivity('twitter')">
                            <i class="fab fa-fw fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a :href="linkedinShareUrl" target="_blank" @click="logShareActivity('linkedin')">
                            <i class="fab fa-fw fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a :href="course.public_url" @click.prevent="copyPublicUrl">
                            <i class="far fa-fw fa-link"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div v-if="course.topics.length > 0" class="mb-2">
                    <h4 @click="toggleAccordion('topics')" :class="['accordian-head', isOpen('topics') ? 'active' : '']">Topics
                      <router-link v-if="featuredTopic" :to="{ name: 'topics', query: { topics: featuredTopic.id } }" class="tag">{{ featuredTopic.name }}</router-link>
                    </h4>
                    <ul v-show="isOpen('topics')" class="tag-list">
                      <li
                        v-for="topic in course.topics"
                        :key="`topic-${topic.id}`"
                        class="me-1"
                      >
                        <router-link :to="{ name: 'topics', query: { topics: topic.id } }" class="tag">{{ topic.name }}</router-link>
                      </li>
                    </ul>
                </div>

                <div v-if="course.primary_fields.length > 0" class="mb-2">
                    <h4 @click="toggleAccordion('primaryFields')" :class="['accordian-head', isOpen('primaryFields') ? 'active' : '']">Primary Fields
                      <span v-if="firstSortedPrimaryField" class="tag disabled">{{ firstSortedPrimaryField.name }}</span>
                    </h4>
                    <ul  v-show="isOpen('primaryFields')" class="tag-list">
                      <li
                        v-for="primary_field in course.primary_fields"
                        :key="`primaryfield-${primary_field.id}`"
                        class="me-1"
                      >
                      <span class="tag disabled">{{ primary_field.name }}</span>
                      </li>
                    </ul>
                </div>

                <div v-if="course.professions.length > 0" class="mb-2">
                    <h4 @click="toggleAccordion('professions')" :class="['accordian-head', isOpen('professions') ? 'active' : '']">Professions
                      <span v-if="firstSortedProfession" class="tag disabled">{{ firstSortedProfession.name }}</span>
                    </h4>
                    <ul v-show="isOpen('professions')" class="tag-list">
                      <li
                        v-for="profession in course.professions"
                        :key="`profession-${profession.id}`"
                        class="me-1"
                      >
                        <span class="tag disabled">{{ profession.name }}</span>
                      </li>
                    </ul>
                </div>

                <div v-html="course.description"/>
              </div>
            </section>
            <section class="col-xl-4">
              <div class="card-body card-body-lg">
                <a
                  :href="course.booking_url"
                  target="_blank"
                  class="btn btn-md btn-primary w-100 mb-3"
                  @click="logBookNowActivity"
                >
                  Book Now
                </a>

                <button
                  type="button"
                  class="btn btn-md btn-outline-primary w-100 mb-4"
                  v-c-modal:addLearningPlan="{ course }"
                >
                  <i class="far fa-plus"></i> Add To Learning Plan
                </button>
                <div class="text-end mb-4" v-if="course.start_date">
                  <a @click.prevent="addToCalendar" class="text-primary">
                    <i class="far fa-calendar-plus"></i> Email Calendar Link
                  </a>
                </div>
                <div class="row course-meta" v-if="getDisplayPrice">
                  <div class="col">
                    <h3>Price</h3>
                  </div>
                  <div class="col-auto">{{ getDisplayPrice }} {{ course.currency }}</div>
                </div>

                <div class="row course-meta" v-if="course.duration">
                  <div class="col">
                    <h3>Duration</h3>
                  </div>
                  <div class="col-auto">{{ course.duration.formatted }}</div>
                </div>

                <div class="row course-meta" v-if="course.start_date">
                  <div class="col">
                    <h3>Date</h3>
                  </div>
                  <div class="col-auto">
                    <time :datetime="course.starts_at">{{
                      course.start_date
                        | date({
                          parse: "YYYY-MM-DD",
                          format: "DD/MM/YYYY",
                          utc: false,
                        })
                    }}</time>
                  </div>
                </div>

                <div class="row course-meta" v-if="course.start_time">
                  <div class="col">
                    <h3>Start Time</h3>
                  </div>
                  <div class="col-auto">
                    <time :datetime="course.starts_at"
                      >{{
                        course.start_time
                          | time({ format: "hh:mm a", utc: false })
                      }}
                      (GMT
                      {{
                        course.starts_at | date({ format: "Z", utc: true })
                      }})</time
                    >
                  </div>
                </div>

                <div class="course-meta" v-if="course.speakers.length > 0">
                  <h3>Speakers</h3>
                  <ul class="no-list text-muted">
                    <li
                      v-for="(speaker, index) in course.speakers"
                      :key="`speaker-${index}`"
                    >
                      {{ speaker }}
                    </li>
                  </ul>
                </div>

                <div class="course-meta" v-if="course.type === 'in_person'">
                  <h3>Location</h3>

                  <address>
                    <p class="text-muted mb-2">
                      {{ course.address.formatted_address }}
                    </p>

                    <a href="" @click.prevent="scrollToMap" class="text-primary"
                      >View Map</a
                    >
                  </address>
                </div>

                <div class="course-meta">
                  <h3>Having Issues Booking?</h3>

                  <p class="text-muted">
                    We would be happy to assist you if you are having issues
                    making a booking.
                  </p>

                  <a class="text-primary" href="" v-c-modal:getInTouch> Get in Touch </a>
                </div>
              </div>
            </section>
          </div>

          <section class="card-body border-top pb-0">
            <h2 class="mb-2">CPD Value</h2>
          </section>

          <ul class="list-data list-data-card-course no-hover">
            <accreditation-value-list-item
              v-for="accreditationValue in accreditationValues"
              :key="`accreditation-value-${accreditationValue.body.id}`"
              v-bind="{ accreditationValue }"
            />
          </ul>
        </div>

        <div class="row mb-4">
          <average-score-card :provider="course.provider" :course="course"></average-score-card>
        </div>

        <google-map
          id="course-map"
          v-if="course.type === 'in_person'"
          disable-street-view
          v-model="map"
          class="course-map"
          :center="mapCenter"
          :zoom="16"
        >
          <google-map-marker
            :latitude="course.address.latitude"
            :longitude="course.address.longitude"
          />
        </google-map>
      </article>
    </div>

    <slot name="footer"/>

    <add-learning-plan-modal ref="addLearningPlan" @updated="courseLearningPlansUpdated"/>
    <get-in-touch-modal ref="getInTouch"/>
  </fragment>
</template>

<script>
import { call, get, sync } from 'vuex-pathify'
import { http } from '@/utils'
import { placeholders } from '@/utils/placeholders'
import { copyToClipboard } from '@/utils/functions/copy-to-clipboard'
import { toast } from '@chameleon/chameleon-vue'
import AccreditationValueListItem from '@/components/courses/user/AccreditationValueListItem.vue'
import GoogleMap from '@/components/maps/GoogleMap.vue'
import GoogleMapMarker from '@/components/maps/GoogleMapMarker'
import AddLearningPlanModal from '@/components/courses/AddLearningPlanModal.vue'
import CourseNotFound from '@/components/courses/user/CourseNotFound.vue'
import GetInTouchModal from '@/components/users/GetInTouchModal.vue'
import LearnerQualityScore from '@/components/courses/LearnerQualityScore.vue'
import AverageScoreCard from '@/components/AverageScoreCard.vue'
import { cloneDeep } from 'lodash'

export default {
  props: {
    courseId: [Number, String]
  },

  components: {
    AccreditationValueListItem,
    AddLearningPlanModal,
    GoogleMap,
    GoogleMapMarker,
    CourseNotFound,
    GetInTouchModal,
    LearnerQualityScore,
    AverageScoreCard
  },

  data () {
    return {
      openAccordions: [],
      map: null,
      courseNotFound: false
    }
  },

  computed: {
    course: get('courses/course'),
    accreditationValues: get('courses/accreditationValues/accreditationValues'),
    liked: sync('courses/course@liked'),
    likesCount: sync('courses/course@likes_count'),
    placeholders () {
      return placeholders
    },

    featuredImageUrl () {
      return this.course.featured_image_url ?? placeholders.large
    },

    featuredTopic () {
      return this.course.topics.find(topic => topic.featured) || null
    },

    firstSortedPrimaryField () {
      const sortedPrimaryFields = cloneDeep(this.course.primary_fields).sort((a, b) => a.name.localeCompare(b.name))
      return sortedPrimaryFields.length > 0 ? sortedPrimaryFields[0] : null
    },

    firstSortedProfession () {
      const sortedProfessions = cloneDeep(this.course.professions).sort((a, b) => a.name.localeCompare(b.name))
      return sortedProfessions.length > 0 ? sortedProfessions[0] : null
    },

    mapCenter () {
      const { latitude, longitude } = this.course.address

      return { lat: latitude, lng: longitude }
    },

    title () {
      return this.course.id ? this.course.name : (this.courseNotFound ? 'Course Not Found' : null)
    },

    facebookShareUrl () {
      return `http://www.facebook.com/sharer.php?display=page&u=${this.course.public_url}`
    },

    twitterShareUrl () {
      return `http://twitter.com/share?text=${this.course.name}&url=${this.course.public_url}`
    },

    linkedinShareUrl () {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${this.course.public_url}`
    },

    getDisplayPrice () {
      if (this.course.price == null) {
        return false
      }
      if (parseFloat(this.course.price) === 0) {
        return 'Free'
      }
      return this.$options.filters.money(parseFloat(this.course.price))
    }
  },

  watch: {
    courseId: {
      async handler (id) {
        try {
          await this.getCourse({ id, request: { throwForbiddens: true } })

          await this.getAccreditationValues({ valid: true })
        } catch {
          this.courseNotFound = true
        }
      },
      immediate: true
    }
  },

  beforeDestroy () {
    this.resetCourse()
  },

  methods: {
    getCourse: call('courses/getCourse'),
    setCourse: call('courses/setCourse'),
    getAccreditationValues: call('courses/accreditationValues/getAccreditationValues'),
    resetCourse: call('courses/resetCourse'),
    storeUserActivity: call('users/storeUserActivity'),

    toggleAccordion (section) {
      const index = this.openAccordions.indexOf(section)
      if (index === -1) {
        this.openAccordions.push(section) // Open accordion if not already open
      } else {
        this.openAccordions.splice(index, 1) // Close accordion if already open
      }
    },
    isOpen (section) {
      return this.openAccordions.includes(section) // Check if accordion is open
    },

    async copyPublicUrl () {
      await copyToClipboard(this.course.public_url)
      await this.logShareActivity('link')

      toast('Link copied to clipboard.', 'success')
    },

    async logShareActivity (platform) {
      await this.storeUserActivity({ activity: 'share', subjectType: 'course', subjectId: this.course.id, properties: { platform } })
    },

    async logBookNowActivity () {
      await this.storeUserActivity({ activity: 'book_now', subjectType: 'course', subjectId: this.course.id })
    },

    courseLearningPlansUpdated (courses) {
      if (courses.length === 1) {
        this.setCourse(courses[0])
      }
    },

    scrollToMap () {
      const element = document.querySelector('#course-map')

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },

    async addToCalendar () {
      try {
        await http.get(`courses/${this.courseId}/calendar-link`)
        toast('Calendar link email sent.', 'success')
      } catch (error) {
        if (error.response.status === 403) {
          return
        }

        toast('Something went wrong when trying send calendar link.', 'danger')
      }
    },

    async likeOrUnlike () {
      try {
        await http.put(`courses/${this.courseId}/like`)

        this.liked = !this.liked

        this.likesCount = this.likesCount + (this.liked ? 1 : -1)
      } catch {
        toast('Something went wrong when trying to like or unlike the course.', 'danger')
      }
    },
  }
}
</script>
